import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CreateableSelect from 'react-select/creatable';
import useLoader from '../hooks/useLoader';
import { generateCreationLabel } from '../lib/generateCreationLabel';
import { createableSelectStyles } from '../lib/styles';
import Category from '../models/Category';
import uuid from '../utils/uuid';

export const AddCategoryForm = ({
  searchPool,
  type: categoryType,
  onSubmit,
}) => {
  const loader = useLoader();

  const [searching, setSearching] = useState(false);

  const { t } = useTranslation();

  const selectOptions = useMemo(() => {
    return searchPool.map(item => ({
      id: item.id,
      label: item.name,
      value: item,
    }));
  }, [searchPool]);

  const fullReset = () => {
    setSearching(false);
  };

  const handleSelect = async selected => {
    const { value, __isNew__: isNew } = selected;

    const temp = isNew ? { name: value } : value;

    const newCategory = new Category({
      ...temp,
      type: categoryType,
      id: uuid(),
    });

    try {
      loader.startLoading();

      await onSubmit(newCategory);

      fullReset();

      loader.stopLoading();
    } catch (e) {
      loader.setError(e);

      console.error(e);
      window.alert(t('itemCreationError'));
    }
  };

  const handleCancel = event => {
    event.preventDefault();

    fullReset();
  };

  return (
    <div className="row justify-content-center">
      <div className="col-12 col-lg-8">
        {searching ? (
          <div className="form-row">
            <div className="col-9">
              <CreateableSelect
                autoFocus
                openMenuOnFocus

                placeholder={t('name')}
                styles={createableSelectStyles}
                options={selectOptions}
                onChange={handleSelect}
                noOptionsMessage={() => t('nothingFound')}
                formatCreateLabel={generateCreationLabel(t('createCategory'))}
              />
            </div>

            <div className="col-3 d-flex justify-content-end">
              <button
                className="btn btn-warning text-nowrap w-100"
                onClick={handleCancel}
              >
                <i className="fa fa-times fa-fw mr-1" />
                {t('cancel')}
              </button>
            </div>
          </div>
        ) : (
          <button
            className="bg-primary btn text-white w-100"
            onClick={() => setSearching(true)}
          >
            <span className="fa fa-fw fa-plus mr-2" />
            {t('addCategory')}
          </button>
        )}
      </div>
    </div>
  );
};
