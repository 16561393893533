import monetize from '../utils/monetize';
import Model from './Model';

export default class Beverage extends Model  {
  constructor(data) {
    super(data);


    const {position, originPosition, required} = data;
    // ensures position will always be at least null
    this.position = position === undefined ? null : position;
    this.originPosition = originPosition === undefined ? null : originPosition;
  }


  get movable() {
    return !this.required;
  }

  get domNode() {
    throw TypeError('No DOM Node available for generic beverage');
  }

  get formattedPrice() {
    return monetize.format(this.price);
  }
}
