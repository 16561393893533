type CurrencyConfig = {};

const monetize = {
  init(config: CurrencyConfig) {
    this.config = config;
  },

  format(value: number) {
    if (!this.config || !value) {
      return value;
    }

    const { format, locale, precision, separator, unit } = this.config;
    if (locale === 'cs') {
      return value || value === 0 ? `${Number(value).toFixed(0)},-` : null;
    }

    let stringValue = '';

    if (typeof value === 'number') {
      stringValue = value.toFixed(precision);
    } else if (typeof value === 'string') {
      const floatValue = parseFloat(value);
      if (isNaN(floatValue)) {
        stringValue = value;
      } else {
        stringValue = floatValue.toFixed(precision);
      }
    } else {
      stringValue = value;
    }

    const formattedValue = stringValue.toString().replace('.', separator);

    return format.replace('%n', formattedValue).replace('%u', unit);
  },
};

export default monetize;