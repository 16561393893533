import React from 'react';
import { useTranslation } from 'react-i18next';
import './BannerComponent.scss';
import { useConfigContext } from '../hooks/useConfigContext';
import useLoader from '../hooks/useLoader';
import useMenuContext from '../hooks/useMenuContext';

export default function BannerComponent({ banner, onClick = null }) {
  const { t } = useTranslation();

  const { deleteBanner } = useMenuContext();
  const { selectedTitlePage } = useConfigContext();

  const loader = useLoader();

  const handleDelete = async () => {
    try {
      loader.startLoading();
      await deleteBanner(banner);
    } catch (e) {
      console.error(e);
      window.alert(t('itemDeletionError'));
      loader.setError(e);
    }
  };

  const submittable = !loader.loading;

  return (
    <div className={`card overflow-hidden mt-3 banner-card--${selectedTitlePage.code} ${onClick ? 'pointer' : ''}`}
         onClick={onClick || (() => {})}
    >
      {!onClick && (
        <div className="card-title d-flex justify-content-end p-3">

          <button
            className="btn btn-sm btn-danger ml-2"
            onClick={handleDelete}
            disabled={!submittable}
          >
            <i className="fa fa-fw fa-trash" />
          </button>
        </div>
      )}

      <div className="card-body">
        <img className="banner__image w-100" src={banner.imagePreviewPath}  alt={`${banner.name} banner photo`}/>
      </div>
    </div>
  );
}
