import React, { useEffect, useMemo } from 'react';
import { useImmer } from 'use-immer';
import useAPI from '../hooks/useAPI';
import useDataContext from '../hooks/useDataContext';
import useNavigationContext from '../hooks/useNavigationContext';
import { calculateInsertionIndex } from '../lib/calculateInsertionIndex';
import { compareByPosition } from '../lib/compareByPosition';
import { APPEARANCE, BANNER, BEVERAGE_TYPES, EMPTY_BLOCK } from '../lib/constants';
import { constructArrayFromIdsAndHash } from '../lib/constructArrayFromIdsAndHash';
import { useConfigContext } from '../hooks/useConfigContext';
import Banner from '../models/Banner';
import Category from '../models/Category';
import Cocktail from '../models/Cocktail';
import Drink from '../models/Drink';
import PhotoCocktail from '../models/PhotoCocktail';

const MenuContext = React.createContext({});

export default MenuContext;

const { COCKTAIL, PHOTO_COCKTAIL, DRINK } = BEVERAGE_TYPES;

export const MenuProvider = ({ children }) => {
  const { drinkMenu: initDrinkMenu } = useDataContext();

  const { setCurrentRoute } = useNavigationContext();
  const {
    selectedTitlePage,
    selectedBlockFormat,
  } = useConfigContext();

  const API = useAPI();

  const {
    menuItemsInit,
    menuCategoryIdsToListsOfMenuItemIds,
    photoCocktailIdsInit,
    bannerIdsInit,
  } = useMemo(() => {
    // { categoryId: [itemId, itemId, ...] }
    // will use this later when initialising the menuCategories
    let menuCategoryIdsToListsOfMenuItemIds = {};
    let photoCocktailIdsInit = [];

    let bannerIdsInit = {
      [PHOTO_COCKTAIL]: [],
      [COCKTAIL]: [],
      [DRINK]: [],
      [EMPTY_BLOCK]: [],
    };

    const menuItemsInit = Object.fromEntries(
      initDrinkMenu.items.sort(compareByPosition).map(item => {
        // TODO: this is NOT a place for deserailzation
        item = { ...item };
        item.type = item.type
          .split('_')
          .slice(0, -1)
          .join('_');

        if (item[item.type] && item[item.type].id)
          item.originId = item[item.type].id;

        if (item.type === BANNER) {
          item = new Banner(item);

          bannerIdsInit[item.beverageType].push(item.id);

          return [item.id, item];
        }

        if (item.type === PHOTO_COCKTAIL) {
          item = new PhotoCocktail(item);

          photoCocktailIdsInit.push(item.id);

          return [item.id, item];
        } else {
          if (item.type === COCKTAIL) {
            item = new Cocktail(item);
          }

          if (item.type === DRINK) {
            item = new Drink(item);
          }

          // TODO: this is NOT the place for deserailzation [2]
          item.categoryId = item[`${item.type}MenuCategory`].id;
          delete item[`${item.type}MenuCategory`];

          // gather all beverages under their respective categoryIds
          menuCategoryIdsToListsOfMenuItemIds[item.categoryId]
            ? menuCategoryIdsToListsOfMenuItemIds[item.categoryId].push(item)
            : (menuCategoryIdsToListsOfMenuItemIds[item.categoryId] = [item]);
        }

        return [item.id, item];
      })
    );

    menuCategoryIdsToListsOfMenuItemIds = Object.fromEntries(
      Object.entries(menuCategoryIdsToListsOfMenuItemIds).map(
        ([categoryId, items]) => {
          items = items
            .sort(compareByPosition)
            .reduce((res, item) => [...res, item.id], []);
          return [categoryId, items];
        }
      )
    );

    return {
      menuItemsInit,
      menuCategoryIdsToListsOfMenuItemIds,
      photoCocktailIdsInit,
      bannerIdsInit,
    };
  }, []);

  const {
    initMenuCategories,
    initCocktailCategoryIds,
    initDrinkCategoryIds,
  } = useMemo(() => {
    let { menuCategories: menuCategoriesDataInit } = initDrinkMenu;

    const initMenuCategories = menuCategoriesDataInit.reduce(
      (result, categoryData) => {
        const category = new Category({
          ...categoryData,
          beverageIds: menuCategoryIdsToListsOfMenuItemIds[categoryData.id],
        });

        if (categoryData.category) {
          category.originId = categoryData.category.id;
        }

        return {
          ...result,
          [category.id]: category,
        };
      },

      {}
    );

    return {
      initMenuCategories,
      initCocktailCategoryIds: menuCategoriesDataInit
        .filter(c => c.type === COCKTAIL)
        .map(c => c.id),
      initDrinkCategoryIds: menuCategoriesDataInit
        .filter(c => c.type === DRINK)
        .map(c => c.id),
    };
  }, []);

  const [cocktailCategoryIds, updateCocktailCategoryIds] = useImmer(
    initCocktailCategoryIds
  );

  const [drinkCategoryIds, updateDrinkCategoryIds] = useImmer(
    initDrinkCategoryIds
  );

  const [photoCocktailIds, updatePhotoCocktailIds] = useImmer(
    photoCocktailIdsInit
  );

  const [menuCategories, updateMenuCategories] = useImmer(initMenuCategories);

  const menuCategoriesContainWhiskeyBourbon = useMemo(
    () =>
      Object.values(menuCategories).some(
        ({ name }) => name === 'Whisk(e)y & Bourbon'
      ),
    [menuCategories]
  );

  const [menuItems, updateMenuItems] = useImmer(menuItemsInit);

  const photoCocktails = useMemo(() => {
    return constructArrayFromIdsAndHash(
      photoCocktailIds,
      menuItems,
      false
      /*
        This sorting is necessary as we don't alter photoCocktails position upon adding them.
        They stay sorted in accord with their position attribute assigned on the backend and it's never changed by the builder.

        Other entities in this system actually use the array as a way to track and discover their positions.
      */
    ).sort(compareByPosition);
  }, [photoCocktailIds, menuItems]);

  const cocktailCategories = useMemo(() => {
    return constructArrayFromIdsAndHash(cocktailCategoryIds, menuCategories);
  }, [cocktailCategoryIds, menuCategories]);

  const drinkCategories = useMemo(() => {
    return constructArrayFromIdsAndHash(drinkCategoryIds, menuCategories);
  }, [drinkCategoryIds, menuCategories]);

  const addBeverage = async (beverageToAdd, category) => {
    // prevent aliasing issues
    const beverage = beverageToAdd.constructor(beverageToAdd);

    const { type } = beverage;

    if (type === PHOTO_COCKTAIL) {
      const { id, imagePreviewPath, imagePath } = await API.createItem(beverage);

      beverage.id = id;
      beverage.imagePreviewPath = imagePreviewPath;
      beverage.imagePath = imagePath;

      const insertionIndex = beverage.position;

      updatePhotoCocktailIds(draft => {
        draft.splice(insertionIndex, 0, id);
      });
    } else {
      const { beverageIds } = menuCategories[category.id] || category;

      const existingBeverages = constructArrayFromIdsAndHash(
        beverageIds,
        menuItems
      );

      const insertionIndex = calculateInsertionIndex(
        beverage,
        existingBeverages,
        true
      );

      const beveragesToUpdate = existingBeverages.slice(insertionIndex);

      beverage.position = insertionIndex;

      const [{ id }] = await Promise.all([
        API.createItem(beverage, category),
        ...beveragesToUpdate.map(b => {
          b.position += 1;
          return API.updateItem(b, category);
        }),
      ]);

      beverage.id = id;

      updateMenuCategories(draft => {
        if (!draft[category.id]) draft[category.id] = category;

        draft[category.id].beverageIds = [...beverageIds];

        draft[category.id].beverageIds.splice(insertionIndex, 0, id);
      });
    }

    updateMenuItems(draft => {
      draft[beverage.id] = beverage;
    });

    return beverage;
  };

  const deleteBeverage = async (beverage, category) => {
    await API.deleteItem(beverage);

    const { id, type } = beverage;

    if (type === PHOTO_COCKTAIL) {
      updatePhotoCocktailIds(draft => {
        return draft.filter(b => b.id !== id);
      });
    } else {
      updateMenuCategories(draft => {
        draft[category.id].beverageIds = draft[category.id].beverageIds.filter(
          b => b.id !== id
        );
      });
    }

    updateMenuItems(draft => {
      delete draft[id];
    });
  };

  const updateBeverage = async (beverage, category) => {
    await API.updateItem(beverage, category);

    updateMenuItems(draft => {
      draft[beverage.id] = beverage;
    });
  };

  const moveBeverageWithinCategory = async (
    oldIndex,
    newIndex,
    beverageId,
    categoryId
  ) => {
    if (oldIndex === newIndex) return;

    const category = menuCategories[categoryId];

    const movedBeverage = menuItems[beverageId];

    const beverageIds = Array.from(category.beverageIds);

    beverageIds.splice(oldIndex, 1);

    const beveragesWithoutMoved = constructArrayFromIdsAndHash(
      beverageIds,
      menuItems
    );
    const insertionIndex = calculateInsertionIndex(
      { ...movedBeverage, position: newIndex },
      beveragesWithoutMoved
    );

    if (oldIndex === insertionIndex) return;

    beverageIds.splice(insertionIndex, 0, beverageId);

    const beveragesWithMoved = constructArrayFromIdsAndHash(
      beverageIds,
      menuItems
    );

    let beveragesToUpdate = [];

    if (oldIndex < insertionIndex) {
      beveragesToUpdate = beveragesWithMoved.slice(
        oldIndex,
        insertionIndex + 1
      );
    } else {
      beveragesToUpdate = beveragesWithMoved.slice(
        insertionIndex,
        oldIndex + 1
      );
    }

    updateMenuCategories(draft => {
      draft[categoryId].beverageIds = beverageIds;
      draft[categoryId] = new Category(category);
    });

    await Promise.all(beveragesToUpdate.map(b => API.updateItem(b, category)));
  };

  const moveBeverageAcrossCategories = async (
    oldIndex,
    newIndex,
    oldCategoryId,
    newCategoryId,
    beverageId
  ) => {
    if (oldCategoryId === newCategoryId)
      return moveBeverageWithinCategory(
        oldIndex,
        newIndex,
        beverageId,
        oldCategoryId
      );

    const oldCategoryBeverageIds = Array.from(
      menuCategories[oldCategoryId].beverageIds
    );

    const newCategoryBeverageIds = Array.from(
      menuCategories[newCategoryId].beverageIds
    );

    const movedBeverage = menuItems[beverageId];

    const newCategoryBeverages = constructArrayFromIdsAndHash(
      newCategoryBeverageIds,
      menuItems
    );

    const insertionIndex = calculateInsertionIndex(
      { ...movedBeverage, position: newIndex },
      newCategoryBeverages
    );

    movedBeverage.position = insertionIndex;

    oldCategoryBeverageIds.splice(oldIndex, 1);
    newCategoryBeverageIds.splice(insertionIndex, 0, beverageId);

    updateMenuCategories(draft => {
      draft[oldCategoryId].beverageIds = oldCategoryBeverageIds;
      draft[newCategoryId].beverageIds = newCategoryBeverageIds;

      draft[newCategoryId] = new Category(draft[newCategoryId]);
    });

    if (oldCategoryId === newCategoryId) {
      await Promise.all(
        newCategoryBeveragesWithMoved.map(b =>
          API.updateItem(b, menuCategories[newCategoryId])
        )
      );

      return;
    }

    const oldCategoryBeveragesWithoutMoved = constructArrayFromIdsAndHash(
      oldCategoryBeverageIds,
      menuItems
    );

    const newCategoryBeveragesWithMoved = constructArrayFromIdsAndHash(
      newCategoryBeverageIds,
      menuItems
    );

    const oldCategoryBeveragesToUpdate = oldCategoryBeveragesWithoutMoved.slice(
      oldIndex
    );

    const newCategoryBeveragesToUpdate = [
      movedBeverage,
      ...newCategoryBeveragesWithMoved.slice(insertionIndex + 1),
    ];

    await Promise.all([
      ...oldCategoryBeveragesToUpdate.map(b =>
        API.updateItem(b, menuCategories[oldCategoryId])
      ),
      ...newCategoryBeveragesToUpdate.map(b =>
        API.updateItem(b, menuCategories[newCategoryId])
      ),
    ]);
  };

  const addCategory = async category => {
    // prevent aliasing issues
    category = new Category(category);

    const categories =
      category.type === DRINK
        ? drinkCategories
        : cocktailCategories;

    const insertionIndex = category.movable
      ? calculateInsertionIndex(category, categories, true)
      : category.position;

    category.position = insertionIndex;

    const categoriesToUpdate = categories.slice(insertionIndex);

    const [{ id }] = await Promise.all([
      API.createCategory(category),
      // increment the positions of all items that follow by one
      ...categoriesToUpdate.map(c =>
        API.updateCategory(new Category({ ...c, position: c.position + 1 }))
      ),
    ]);

    category.id = id;

    updateMenuCategories(draft => {
      draft[id] = category;
    });

    const update =
      category.type === DRINK
        ? updateDrinkCategoryIds
        : updateCocktailCategoryIds;

    update(draft => {
      draft.splice(insertionIndex, 0, id);
    });

    return category;
  };

  const updateCategory = async category => {
    await API.updateCategory(category);

    updateMenuCategories(draft => {
      draft[category.id] = category;
    });
  };

  const deleteCategory = async category => {
    await API.deleteCategory(category);

    updateMenuCategories(draft => {
      delete draft[category.id];
    });
  };

  const moveCategory = async (oldIndex, newIndex, type) => {
    /*
     Don't allow anything to be moved to the first index in drink categories as it's reserved for Tennessee Whiskey.

     P.S. Could be extracted into a nice sorting policy mechanism, but the deadlines are merciless

     Edit: ALSO Don't allow anything to be moved to the 2nd index, as it's reserved for Whiskey
    */
    if (type === DRINK && newIndex === 0) return;

    if (
      type === DRINK &&
      menuCategoriesContainWhiskeyBourbon &&
      newIndex === 1
    )
      return;

    const ids =
      type === DRINK
        ? [...drinkCategoryIds]
        : [...cocktailCategoryIds];

    const [id] = ids.splice(oldIndex, 1);

    const moved = menuCategories[id];

    const categoriesWithoutMoved = constructArrayFromIdsAndHash(
      ids,
      menuCategories
    );

    const movedAtNewPosition = new Category({
      ...moved,
      position: newIndex,
    });

    const insertionIndex = calculateInsertionIndex(
      movedAtNewPosition,
      categoriesWithoutMoved
    );

    ids.splice(insertionIndex, 0, id);

    const categoriesWithMoved = constructArrayFromIdsAndHash(
      ids,
      menuCategories
    );

    if (oldIndex === insertionIndex) return;

    let categoriesToUpdate = [];

    if (oldIndex < insertionIndex) {
      categoriesToUpdate = categoriesWithMoved.slice(
        oldIndex,
        insertionIndex + 1
      );
    } else {
      categoriesToUpdate = categoriesWithMoved.slice(
        insertionIndex,
        oldIndex + 1
      );
    }

    const update =
      type === DRINK
        ? updateDrinkCategoryIds
        : updateCocktailCategoryIds;

    update(() => ids);

    await Promise.all(categoriesToUpdate.map(c => API.updateCategory(c)));
  };

  const movePhotoCocktail = async (oldIndex, newIndex) => {
    if (oldIndex === newIndex) return;

    const movedPhotoCocktail = photoCocktails[oldIndex];

    const ids = Array.from(photoCocktailIds);

    ids.splice(oldIndex, 1);

    const photoCocktailsWithoutMoved = constructArrayFromIdsAndHash(
      ids,
      menuItems
    );

    const insertionIndex = calculateInsertionIndex(
      {
        ...movedPhotoCocktail,
        position: newIndex,
      },
      photoCocktailsWithoutMoved
    );

    ids.splice(insertionIndex, 0, movedPhotoCocktail.id);

    const photoCocktailsWithMoved = constructArrayFromIdsAndHash(
      ids,
      menuItems
    );

    if (oldIndex === insertionIndex) return;

    let photoCocktailsToUpdate = [];

    if (oldIndex < insertionIndex) {
      photoCocktailsToUpdate = photoCocktailsWithMoved.slice(
        oldIndex,
        insertionIndex + 1
      );
    } else {
      photoCocktailsToUpdate = photoCocktailsWithMoved.slice(
        insertionIndex,
        oldIndex + 1
      );
    }

    updatePhotoCocktailIds(() => ids);

    await Promise.all(photoCocktailsToUpdate.map(c => API.updateItem(c)));
  };

  // Banners

  const [photoCocktailBannerIds, updatePhotoCocktailBannerIds] = useImmer(
    bannerIdsInit[PHOTO_COCKTAIL]
  );

  const [cocktailBannerIds, updateCocktailBannerIds] = useImmer(
    bannerIdsInit[COCKTAIL]
  );

  const [drinkBannerIds, updateDrinkBannerIds] = useImmer(
    bannerIdsInit[DRINK]
  );

  const [emptyBlockBannerIds, updateEmptyBlockBannerIds] = useImmer(
    bannerIdsInit[EMPTY_BLOCK]
  );

  const photoCocktailBanners = useMemo(
    () => constructArrayFromIdsAndHash(photoCocktailBannerIds, menuItems),
    [photoCocktailBannerIds, menuItems]
  );

  const cocktailBanners = useMemo(
    () => constructArrayFromIdsAndHash(cocktailBannerIds, menuItems),
    [cocktailBannerIds, menuItems]
  );

  const drinkBanners = useMemo(
    () => constructArrayFromIdsAndHash(drinkBannerIds, menuItems),
    [drinkBannerIds, menuItems]
  );

  const emptyBlockBanners = useMemo(
    () => constructArrayFromIdsAndHash(emptyBlockBannerIds, menuItems),
    [emptyBlockBannerIds, menuItems]
  );

  const addBanner = async banner => {
    const { beverageType, section } = banner;

    let banners;
    let update;

    if (beverageType === DRINK) {
      banners = drinkBanners;
      update = updateDrinkBannerIds;
    }

    if (beverageType === COCKTAIL) {
      banners = cocktailBanners;
      update = updateCocktailBannerIds;
    }

    if (beverageType === PHOTO_COCKTAIL) {
      banners = photoCocktailBanners;
      update = updatePhotoCocktailBannerIds;
    }

    if (section === EMPTY_BLOCK) {
      banners = emptyBlockBanners;
      update = updateEmptyBlockBannerIds;
    }

    if (!banners) throw Error('No banners found for beverage type');

    const insertionIndex = calculateInsertionIndex(banner, banners);

    banner.position = insertionIndex;

    const bannersToUpdate = banners.slice(insertionIndex);

    const [{ id }] = await Promise.all([
      API.createItem(banner),
      ...bannersToUpdate.map(c => {
        c.position += 1;
        return API.updateItem(c);
      }),
    ]);

    banner.id = id;

    update(draft => {
      draft.splice(insertionIndex, 0, id);
    });

    updateMenuItems(draft => {
      draft[banner.id] = banner;
    });
  };

  const deleteBanner = async banner => {
    await API.deleteItem(banner);

    const { beverageType, section } = banner;

    let update;

    if (beverageType === PHOTO_COCKTAIL)
      update = updatePhotoCocktailBannerIds;

    if (beverageType === COCKTAIL)
      update = updateCocktailBannerIds;

    if (beverageType === DRINK) update = updateDrinkBannerIds;

    if (section === EMPTY_BLOCK) update = updateEmptyBlockBannerIds;

    update(banners => banners.filter(({ id }) => id !== banner.id));

    updateMenuItems(draft => {
      delete draft[banner.id];
    });
  };

  const deleteAllBanners = async () => {
    const allBanners = [
      ...photoCocktailBanners,
      ...cocktailBanners,
      ...drinkBanners,
      ...emptyBlockBanners,
    ];
    await Promise.all(allBanners.map(deleteBanner));
  };

  useEffect(() => {
    const saveItemsWithMenuPositions = async () => {
      try {
        const { categories, items } = Object.values(menuCategories).reduce(
          (result, category) => {
            result.categories.push(category);

            category.beverageIds.forEach(id => {
              const item = menuItems[id];
              item.category = category;
              result.items.push(item);
            });

            return result;
          },
          { categories: [], items: [...photoCocktails] }
        );

        await Promise.all([
          ...categories.map(API.updateCategory),
          ...items.map(item => API.updateItem(item, item.category)),
        ]);
      } catch (err) {
        console.error(err);
      }
    };

    // if initDrinkMenu has no htmlBody, it's freshly created
    const menuIsNew = !initDrinkMenu.htmlBody;

    if (menuIsNew) {
      setCurrentRoute(APPEARANCE);
      saveItemsWithMenuPositions();
    } else {
      const wasPrefilled = (new URLSearchParams(window.location.search)).has('prefill');

      if (wasPrefilled) {
        history.replaceState({}, document.title, window.location.pathname);
        saveItemsWithMenuPositions();
        console.log('was prefilled');
      }

      if (!selectedTitlePage.deleted) {
        if (selectedBlockFormat === 'A5_summer' || selectedBlockFormat === 'A5_winter') {
          setCurrentRoute(PHOTO_COCKTAIL);
        } else {
          setCurrentRoute(DRINK);
        }
      }
    }
  }, []);

  const photoCocktailBannersAllowed = !cocktailCategories.length;

  // console.group('drink categories');
  // drinkCategories.map(({ name, position, originPosition }) =>
  //   console.log(
  //     `${name} - position: ${position} - originPosition: ${originPosition}`
  //   )
  // );
  // console.groupEnd();

  return (
    <MenuContext.Provider
      value={{
        menuCategories,
        menuItems,

        photoCocktails,
        movePhotoCocktail,

        cocktailCategories,
        drinkCategories,

        addBeverage,
        updateBeverage,
        deleteBeverage,
        moveBeverageAcrossCategories,

        deleteAllBanners,

        addCategory,
        updateCategory,
        deleteCategory,
        moveCategory,

        addBanner,
        deleteBanner,

        photoCocktailBanners,
        cocktailBanners,
        drinkBanners,
        emptyBlockBanners,

        photoCocktailBannersAllowed,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};
