export function producePreviewHTMLBodyFromBlocks(blocks, selectedBlockFormat, selectedTitlePage) {
  const renderer = document.createElement('div');
  renderer.classList.add('renderer');
  renderer.classList.add(selectedBlockFormat);
  renderer.classList.add(selectedTitlePage.code);

  blocks.forEach(block => {renderer.appendChild(block.cloneNode(true))})

  return renderer.outerHTML;
}
