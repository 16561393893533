import { cloneDeep } from 'lodash';
import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useMenuContext from './useMenuContext';

export const useSearch = (searchPool, onSelect, inCategories = false) => {
  const { menuItems, menuCategories } = useMenuContext();

  const timeoutRef = useRef(null);

  const [showSearchResults, setShowSearchResults] = useState(false);

  const [query, setQuery] = useState('');
  const resetQuery = () => setQuery('');

  const [arrowIndex, setArrowIndex] = useState(null);

  const fullReset = () => {
    setArrowIndex(null);
    setShowSearchResults(false);
  }

  const arrowTargetRef = useRef(null);

  const { t } = useTranslation();

  const itemsFilteredBySearch = useMemo(
    () =>
      searchPool.filter(poolItem => {
        const includesQuery = poolItem.name
          .toLowerCase()
          .includes(query.toLowerCase());

        if (poolItem.type === 'banner') return includesQuery;

        return includesQuery;
      }),
    [menuItems, menuCategories, query]
  );

  const handleSelect = selectedItem => {
    fullReset();

    // some post-kitsu objects contain read-only fields
    // hence the peculiar, seemingly redundant copying
    onSelect(cloneDeep({ ...selectedItem }));
  };

  const searchInputProps = {
    onFocus: () => {
      if (timeoutRef.current) window.clearTimeout(timeoutRef.current);

      setShowSearchResults(true);
    },
    onBlur: () => {
      if (timeoutRef.current) window.clearTimeout(timeoutRef.current);

      timeoutRef.current = window.setTimeout(() => {
        setShowSearchResults(false);
        setArrowIndex(null);
      }, 100);
    },
    onKeyPress: event => {
      event.stopPropagation();

      if (event.key === 'Enter') {
        event.preventDefault();

        if (
          arrowIndex === null ||
          !itemsFilteredBySearch.length ||
          !arrowTargetRef.current
        ) {
          return;
        }

        const chosenItem = arrowTargetRef.current;

        handleSelect(JSON.parse(chosenItem.dataset.item));
      }
    },

    onKeyDown: event => {
      event.stopPropagation();

      const { keyCode } = event;

      // Up arrow
      if (keyCode === 38) {
        if (arrowIndex === null) setArrowIndex(0);
        else if (arrowIndex > 0) setArrowIndex(index => index - 1);
        else return;
      }

      // Down arrow
      if (keyCode === 40) {
        if (arrowIndex === null) setArrowIndex(0);
        else if (arrowIndex < itemsFilteredBySearch.length - 1)
          setArrowIndex(index => index + 1);
        else return;
      }
    },
  };

  const SearchResults = () => {
    if (!showSearchResults) return null;

    if (query.length && !itemsFilteredBySearch.length)
      return <div className="card">{t('nothingFound')}</div>;

    return itemsFilteredBySearch.map((item, index) => {
      const isArrowSelected = index === arrowIndex;

      return (
        <div
          key={item.id}
          data-item={JSON.stringify(item)}
          className="card pointer"
          style={{
            backgroundColor: isArrowSelected ? 'lightblue' : 'white',
          }}
          ref={isArrowSelected ? arrowTargetRef : null}
          onClick={() => handleSelect(item)}
        >
          {item.name}
        </div>
      );
    });
  };

  return {
    searchInputProps,
    query,
    setQuery,
    resetQuery,
    showSearchResults,
    SearchResults,
  };
};
