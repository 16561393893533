import {
  APPEARANCE,
  BEVERAGE_TYPES,
  EMPTY_BLOCK,
  PREVIEW,
} from './lib/constants';

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// TODO: move translations to json files
const { PHOTO_COCKTAIL, COCKTAIL, DRINK } = BEVERAGE_TYPES;
const resources = {
  cs: {
    translation: {
      [`${PHOTO_COCKTAIL}_plural`]: 'Drinky s fotografií',
      [`${COCKTAIL}_plural`]: 'Ostatní drinky',
      [`${DRINK}_plural`]: 'Nápoje',

      blockCount: 'Počet stran',
      template: 'Template',
      format: 'Formát',
      order: 'Objednat',
      settings: 'Nastavení',
      page: 'Strana',

      [PREVIEW]: 'Náhled',
      [EMPTY_BLOCK]: 'Prázdná stránka',

      logo: {
        logo: 'Logo',
        isUploading: 'Nové logo se nahrává',
        successfullyUpdated: 'Logo bylo úspěšně aktualizováno',
      },

      allergensInfo: 'Seznam alergenů na vyžádání u obsluhy.',

      addVariant: 'Přidat další litráž',
      confirm: 'Potvrdit',
      close: 'Zavřít',
      delete: 'Smazat',

      printablePDF: 'Tiskové PDF',

      emptyCategory: 'Prázdná kategorie',

      cancel: 'Storno',
      add: 'Přidat',

      chooseFile: 'Vybrat soubor',

      addCocktail: 'Přidat drink',

      addDrink: 'Přidat nápoj',

      addPhotoCocktail: 'Přidat drink s fotografií',

      addText: 'Přidat zajímavé sdělení',

      addCategory: 'Přidat kategorii',

      addBanner: 'Přidat zajímavé sdělení',
      hideBanner: 'Skrýt zajímavé sdělení',

      preview_title: 'Náhled menu',

      nothingFound: 'Nic nenalezeno',

      itemCreationError: 'Chyba vytváření položky',
      itemUpdateError: 'Chyba akutalizaci stavu položky',
      itemUpdateError_plural: 'Chyba akutalizaci stavu položek',
      itemDeletionError: 'Chyba smazání položky',

      logoUpdateError: 'Chyba nahrávání loga',
      logoUpdateSuccess: 'Logo úspěšně aktualizováno',

      noPriceSet: 'Cena není uvedena',

      drinkPrice: 'Cena nápoje',

      price: 'Cena',
      amount: 'Míra',
      ingredients: 'Ingredience',

      enterPrice: 'Zadat cenu',
      copy: 'Duplikovat',

      name: 'Název',
      address: 'Adresa',

      back: 'Zpět',
      next: 'Další',

      prefillBeverages: 'Předvyplnit menu',
      prefillMessage: 'nebo začněte přidávat nápoje a kategorie',

      photoCocktailsCantHaveBannersIfYouHavePhotolessCocktails:
        'Máte vytvořené vlastní koktejly. Tyto se budou zobrazovat hned pod koktejly s fotografií. Zajímavé sdělení je tedy možné přidat až pod vlastní koktejly.',

      cannotChange: 'nelze změnit',

      [APPEARANCE]: 'Vzhled',

      emptyCocktailsList:
        'Prázdný seznam. Přidejte kategorii a poté přidejte drinky.',
      emptyDrinksList:
        'Prázdný seznam. Přidejte kategorii a poté přidejte nápoje.',

      emptyList: 'Prazdný seznam',

      createCategory: 'Vytvořit kategorii',
      createDrink: 'Vytvořit nápoj',
      createCocktail: 'Vytvořit koktejl',

      from: 'od',
      to: 'do',

      totalPrice: 'Celková cena (bez DPH)',

      OrderPriceForm: {
        quantity: 'Množství',
        pricePerMenu: 'Cena za menu (bez DPH)',
      },

      renderError: "Nastala chyba při zobrazení náhledu. Zkontrolujete prosím položky menu zda neobsahují dlouhá slove.",

      material: 'Materiál',

      explanationTour: {
        menuBuilder: {
          intro: {
            steps:
              'Tvorba probíhá v několika krocích, lze mezi nimi libovolně přepínat',
            viewSwitch:
              'Kdykoliv v průběhu můžete sledovat nahlížet na reálné menu nebo zjistit zapnout/vypnout zobrazení cenu výroby',
            orderPrint:
              '  Po dokončení menu přímo z aplikace objednejte profesionální tisk',
          },
          view: {
            format:
              'Vyberte rozměr menu (formát první strany) a grafický vzhled z nabízených možností',
            pageNum:
              'Počet stran je počítán automaticky průběžně při tvorbě menu (po zaplnění menu se automaticky přidává další dvojstrana)',
            logo:
              'Na úvodní stranu si můžete umístit logo, název a libovolný text (např. adresu provozovny)',
          },
          drinks: {
            order:
              'Nápoje jsou řazeny do připravených kategorií, každá kategorie obsahuje seznam odpovídajících nápojů',
            filter:
              'V nápojích lze rychle filtrovat a vyhledávat - stačí zadat libovolné písmeno nebo text',
            create:
              'Můžete si vytvořit vlastní kategorii i nápoj - napište požadovaný text a klikněte na tlačítko "Přidat vlastní.."',
            drinkContent:
              'Nápoj obsahuje název, litráž a cenu - pro přidání další litráže klikněte na zelené "+" při editaci nápoje',
            changes:
              'Nápoje a kategorie lze libovolně mazat nebo měnit jejich pořadí (s výjimkou kategorie Whiskey a vybraných nápojů Brown Forman Czechia)',
          },
          drinksWithFoto: {
            defaultList:
              "Obsahují pevně daný seznam úspěšných drinků značek Jack Daniel's a Finlandia v neměnném pořadí",
            areOnLastPage:
              'Drinky jsou umístěny vždy na poslední straně (stránkují se odzadu)',
          },
          otherDrinks: {
            coctailsAndMix:
              'Zde můžete přidat koktejly a ostatní míchané nápoje',
            order:
              'Jsou řazeny v kategoriích a řídí se stejnými principy jako přidávání nápojů',
            content:
              'Obsahují název, složení (lze řádkovat pomocí enteru) a cenu',
          },
          emptyPage: {
            whenIsShowed:
              'Tlačítko "5. Prázdná stránka" se v horním menu objeví ve chvíli, kdy zůstane nevyužitá celá stránka v menu',
            fullfil:
              'Zajímavá sdělení využijte jako elegantní vyplnění prázdného prostoru na stránce',
            ifSpaceBig:
              'Pokud je prostor dostatečně velký, objeví se automaticky tlačítko VLOŽIT ZAJÍMAVÉ SDĚLENÍ i v sekci nápoje, drinky s fotografií i ostatní drinky',
            rules:
              'Při změně vzhledu menu nebo vyplnění místa nápoji dojde k odstranění zajímavého sdělení - doporučujeme je proto vkládat až po vložení všech nápojů a drinků',
          },
        },
      },
      AppearanceScreen: {
        selectedTitlePageDeleted: 'Použitá titulka již nejde použít. Bude nastavena první dostupná titulka.',
        titlePageDeleted: 'Tuto titulku již nelze použít. Vyberte prosím jinou.',
        selectBlockFormat: 'Vyberte formát nápojového lístku',
        selectTitlePage: 'Vyberte vzhled nápojového lístku',
        enterTextsToDisplayInTitlePage: 'Zadejte údaje na úvodní stranu menu',
        prefillingOfTheMenu: 'Předvyplnění nápojů do nápojového lístku',

        nextStep: 'Pokračujte kliknutím na další záložku',
        createMenu: 'VYTVOŘIT MENU',
        viewRemovelogo: 'Logo nápojového lístku',
        noLogo: 'Bez loga',

        standardMaterial: 'Standard',
        laminateMaterial: 'Laminát',
        selectMaterial: 'Vyberte materiál',
      },

      preparingPDFForDownload: 'Připravujeme pdf ke stažení',
      HealthPsa: {
        allergens_warning: "Seznam alergenů na vyžádání u obsluhy."
      }
    },
  },
  sk: {
    translation: {
      [`${PHOTO_COCKTAIL}_plural`]: 'Drinky s fotografiou',
      [`${COCKTAIL}_plural`]: 'Ostatné drinky',
      [`${DRINK}_plural`]: 'Nápoje',

      blockCount: 'Počet strán',
      template: 'Šablóna',
      format: 'Formát',
      order: 'Objednať',
      settings: 'Nastavenia',
      page: 'Strana',

      [PREVIEW]: 'Náhľad',
      [EMPTY_BLOCK]: 'Prázdna strana',

      logo: {
        logo: 'Logo',
        isUploading: 'Nové logo sa nahráva',
        successfullyUpdated: 'Logo bolo úspešne aktualizované',
      },

      allergensInfo: 'Zoznam alergénov na požiadanie u obsluhy.',

      addVariant: 'Pridať ďalší objem',
      confirm: 'Potvrdiť',
      close: 'Zavrieť',
      delete: 'Zmazať',

      printablePDF: 'Tlačové PDF',

      emptyCategory: 'Prázdna kategória',

      cancel: 'Storno',
      add: 'Pridať',

      chooseFile: 'Vybrať súbor',

      addCocktail: 'Pridať drink',

      addDrink: 'Pridať nápoj',

      addPhotoCocktail: 'Pridať drink s fotografiou',

      addText: 'Pridať zaujímavé oznámenie',

      addCategory: 'Pridať kategóriu',

      addBanner: 'Pridať zaujímavé oznámenie',
      hideBanner: 'Skryť zaujímavé oznámenie',

      preview_title: 'Náhľad menu',

      nothingFound: 'Nič nenájdené',

      itemCreationError: 'Chyba vytvárania položky',
      itemUpdateError: 'Chyba aktualizácie stavu položky',
      itemUpdateError_plural: 'Chyba aktualizácie stavu položiek',
      itemDeletionError: 'Chyba zmazania položky',

      logoUpdateError: 'Chyba nahrávania loga',
      logoUpdateSuccess: 'Logo úspešne aktualizované',

      noPriceSet: 'Cena nie je uvedená',

      drinkPrice: 'Cena nápoja',

      price: 'Cena',
      amount: 'Objem',
      ingredients: 'Ingrediencie',

      enterPrice: 'Zadať cenu',
      copy: 'Duplikovať',

      name: 'Názov',
      address: 'Adresa',

      back: 'Späť',
      next: 'Ďalej',

      prefillBeverages: 'Predvyplniť menu',
      prefillMessage: 'alebo začnite pridávať nápoje a kategórie',

      photoCocktailsCantHaveBannersIfYouHavePhotolessCocktails:
        'Máte vytvorené vlastné koktaily. Tie sa budú zobrazovať hneď pod koktailmi s fotografiou. Zaujímavé oznámenie je teda možné pridať až pod vlastné koktaily.',

      cannotChange: 'nemožno zmeniť',

      [APPEARANCE]: 'Vzhľad',

      emptyCocktailsList:
        'Prázdny zoznam. Pridajte kategóriu a potom pridajte drinky.',
      emptyDrinksList:
        'Prázdny zoznam. Pridajte kategóriu a potom pridajte nápoje.',

      emptyList: 'Prázdny zoznam',

      createCategory: 'Vytvoriť kategóriu',
      createDrink: 'Vytvoriť nápoj',
      createCocktail: 'Vytvoriť koktejl',

      from: 'od',
      to: 'do',

      totalPrice: 'Celková cena (bez DPH)',

      OrderPriceForm: {
        quantity: 'Množstvo',
        pricePerMenu: 'Cena za menu (bez DPH)',
      },

      renderError: "Nastala chyba pri zobrazení náhľadu. Skontrolujte prosím položky menu či neobsahujú dlhé slová.",

      material: 'Materiál',

      explanationTour: {
        menuBuilder: {
          intro: {
            steps:
              'Tvorba prebieha v niekoľkých krokoch, je možné medzi nimi ľubovoľne prepínať',
            viewSwitch:
              'Kedykoľvek počas tvorby môžete sledovať reálne menu alebo zapnúť/vypnúť zobrazenie ceny výroby',
            orderPrint:
              '  Po dokončení menu priamo z aplikácie objednajte profesionálnu tlač',
          },
          view: {
            format:
              'Vyberte rozmer menu (formát prvej strany) a grafický vzhľad z ponúkaných možností',
            pageNum:
              'Počet strán sa počíta automaticky priebežne počas tvorby menu (po zaplnení menu sa automaticky pridáva ďalšia dvojstrana)',
            logo:
              'Na úvodnú stranu si môžete umiestniť logo, názov a ľubovoľný text (napr. adresu prevádzky)',
          },
          drinks: {
            order:
              'Nápoje sú zaradené do pripravených kategórií, každá kategória obsahuje zoznam zodpovedajúcich nápojov',
            filter:
              'V nápojoch možno rýchlo filtrovať a vyhľadávať - stačí zadať ľubovoľné písmeno alebo text',
            create:
              'Môžete si vytvoriť vlastnú kategóriu i nápoj - napíšte požadovaný text a kliknite na tlačidlo "Pridať vlastný.."',
            drinkContent:
              'Nápoj obsahuje názov, objem a cenu - pre pridanie ďalšieho objemu kliknite na zelené "+" pri editácii nápoja',
            changes:
              'Nápoje a kategórie možno ľubovoľne mazať alebo meniť ich poradie (s výnimkou kategórie Whiskey a vybraných nápojov Brown Forman Slovakia)',
          },
          drinksWithFoto: {
            defaultList:
              "Obsahujú pevne daný zoznam úspešných drinkov značiek Jack Daniel's a Finlandia v nemennom poradí",
            areOnLastPage:
              'Drinky sú umiestnené vždy na poslednej strane (stránkujú sa odzadu)',
          },
          otherDrinks: {
            coctailsAndMix:
              'Tu môžete pridať kokteily a ostatné miešané nápoje',
            order:
              'Sú zaradené v kategóriách a riadia sa rovnakými princípmi ako pridávanie nápojov',
            content:
              'Obsahujú názov, zloženie (možno riadkovať pomocou enteru) a cenu',
          },
          emptyPage: {
            whenIsShowed:
              'Tlačidlo "5. Prázdna strana" sa v hornom menu objaví vo chvíli, keď zostane nevyužitá celá strana v menu',
            fullfil:
              'Zaujímavé oznámenia využite ako elegantné vyplnenie prázdneho priestoru na stránke',
            ifSpaceBig:
              'Ak je priestor dostatočne veľký, objaví sa automaticky tlačidlo VLOŽIŤ ZAUJÍMAVÉ OZNÁMENIE aj v sekcii nápoje, drinky s fotografiou i ostatné drinky',
            rules:
              'Pri zmene vzhľadu menu alebo vyplnení miesta nápojmi dôjde k odstráneniu zaujímavého oznámenia - odporúčame ich preto vkladať až po vložení všetkých nápojov a drinkov',
          },
        },
      },
      AppearanceScreen: {
        selectedTitlePageDeleted: 'Použitá titulka už nejde použiť. Bude nastavená prvá dostupná titulka.',
        titlePageDeleted: 'Túto titulku už nemožno použiť. Vyberte prosím inú.',
        selectBlockFormat: 'Vyberte formát nápojového lístka',
        selectTitlePage: 'Vyberte vzhľad nápojového lístka',
        enterTextsToDisplayInTitlePage: 'Zadajte údaje na úvodnú stranu menu',
        prefillingOfTheMenu: 'Predvyplnenie nápojov do nápojového lístka',

        nextStep: 'Pokračujte kliknutím na ďalšiu záložku',
        createMenu: 'VYTVORIŤ MENU',
        viewRemovelogo: 'Logo nápojového lístka',
        noLogo: 'Bez loga',
        
        standardMaterial: 'Štandard',
        laminateMaterial: 'Laminát',
        selectMaterial: 'Vyberte materiál',
      },

      preparingPDFForDownload: 'Pripravujeme pdf na stiahnutie',
      HealthPsa: {
        allergens_warning: "Zoznam alergénov na požiadanie u obsluhy."
      }
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'cs',

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
