export function compareByPosition(a, b) {
  if (a.position === null) return 1;
  if (b.position === null) return -1;

  return a.position - b.position;
}

export function compareByOriginPosition(a, b) {
  if (a.originPosition === null) return 1;
  if (b.originPosition === null) return -1;

  return a.originPosition - b.originPosition;
}
