export const filterBasedOnTitlePage = (selectedTitlePage) => (
  banner
  ) => {
  const { blockFormat, template, variant } = banner;

  if (template && template !== selectedTitlePage?.template) return false;

  if (variant !== null && selectedTitlePage.variant !== variant) return false;

  return blockFormat === selectedTitlePage?.blockFormat;
};