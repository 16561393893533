import React, { useEffect, useRef } from 'react';

import { useConfigContext } from '../hooks/useConfigContext';
import { useBlocksContext } from '../hooks/useBlocksContext';
import { useTranslation } from 'react-i18next';

export default function Renderer() {
  const { t } = useTranslation();
  const rendererRef = useRef(null);

  const { blocks } = useBlocksContext();

  const { selectedBlockFormat, selectedTitlePage, locale } = useConfigContext();

  useEffect(() => {
    if (!rendererRef.current) return;

    const renderer = rendererRef.current;

    renderer.innerHTML = '';

    blocks.forEach(block => renderer.appendChild(block.cloneNode(true)));
  }, [blocks]);

  return (
    <>
      {window['appError'] && (
        <div className="errors">
          <div className="error-item">{t(window['appError'])}</div>
        </div>
      )}
      <div
        ref={rendererRef}
        className={`renderer ${selectedBlockFormat} ${selectedTitlePage.code} ${locale}`}
      />
    </>
  );
}
