import { BEVERAGE_TYPES } from '../lib/constants';
import Beverage from './Beverage';

export default class Cocktail extends Beverage {
  constructor(data) {
    super(data);
    this.type = BEVERAGE_TYPES.COCKTAIL;
  }

  get domNode() {
    const node = document.createElement('div');
    node.classList.add('cocktail');

    const infoNode = document.createElement('div');
    infoNode.classList.add('cocktail__info')
    node.appendChild(infoNode);

    const nameNode = document.createElement('div');
    nameNode.classList.add('cocktail__name');
    nameNode.innerHTML = this.name;
    infoNode.appendChild(nameNode);

    if (this.ingredients) {
      const ingredientsNode = document.createElement('div');
      ingredientsNode.classList.add('cocktail__ingredients');
      ingredientsNode.innerHTML = this.ingredients;
      infoNode.appendChild(ingredientsNode);
    }

    if (this.formattedPrice) {
      const priceNode = document.createElement('div');
      priceNode.classList.add('cocktail__price');
      priceNode.innerHTML = this.formattedPrice;
      node.appendChild(priceNode);
    }

    return node;
  }

  toRequest(drinkMenu, category) {
    return {
      data: {
        type: 'cocktail_item',
        attributes: {
          drink_menu_id: drinkMenu.id,
          name: this.name,
          price: this.price === 0 ? 0 : this.price || null,
          amount: this.amount,
          ingredients: this.ingredients,
          position: this.position,
          editable: this.editable,

          cocktail_menu_category_id: category.id,
          cocktail_id: this.originId,
          origin_position: this.originPosition,
        },
        // relationships: {
        //   photo_cocktail: { "data": { "id": "40", "type": "cocktail" } }
        // }
      },
    };
  }
}
