export const constructArrayFromIdsAndHash = (ids, hash, overwritePositions = true) =>
  ids.reduce((res, id, index) => {
    const item = hash[id];

    if (!item) return res;

    if (overwritePositions) item.position = index;

    return [...res, item];
  }, []);
