import './CocktailScreen.scss';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import { useTranslation } from 'react-i18next';
import React, { useMemo } from 'react';
import { AddCategoryForm } from '../components/AddCategoryForm';
import BannerSelector from '../components/BannerSelector';
import CategoryComponent from '../components/CategoryComponent';
import { PrefillButton } from '../components/PrefillButton';

import useMenuContext from '../hooks/useMenuContext';
import useSearchPoolContext from '../hooks/useSearchPoolContext';
import { BEVERAGE_TYPES } from '../lib/constants';
import { constructArrayFromIdsAndHash } from '../lib/constructArrayFromIdsAndHash';

export default function CategoriesScreen({ categories, type }) {
  const { t } = useTranslation();

  const {
    addCategory,
    updateCategory,
    moveCategory,
    deleteCategory,

    addBeverage,
    updateBeverage,
    deleteBeverage,
    moveBeverageAcrossCategories,

    menuItems,

    drinkBanners,
    cocktailBanners,
  } = useMenuContext();

  const { categoriesSearchPool } = useSearchPoolContext();

  const banners = useMemo(() => {
    if (type === BEVERAGE_TYPES.DRINK) return drinkBanners;
    if (type === BEVERAGE_TYPES.COCKTAIL) return cocktailBanners;
  }, [type, drinkBanners, cocktailBanners]);

  const handleDragEnd = async responder => {
    const {
      reason,
      source,
      destination,
      type: draggableType,
      draggableId,
    } = responder;

    if (reason === 'CANCEL' || !destination) return;

    const { index: oldIndex } = source;
    const { index: newIndex } = destination;

    if (draggableType === 'category') {
      try {
        await moveCategory(oldIndex, newIndex, type);
      } catch (e) {
        console.error(e);
        window.alert(t('itemUpdateError_plural'));
      }
    }

    if (draggableType.split('-')[0] === 'beverages') {
      try {
        await moveBeverageAcrossCategories(
          oldIndex,
          newIndex,
          source.droppableId,
          destination.droppableId,
          draggableId
        );
      } catch (e) {
        console.error(e);
        window.alert(t('itemUpdateError_plural'));
      }
    }
  };

  return (
    <div className="container">
      {type === BEVERAGE_TYPES.DRINK && (
        <div className="row mb-4">
          <div className="col-12">
            <PrefillButton />
          </div>
        </div>
      )}

      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="CategoriesScreen" type="category">
          {provided => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {categories.length ? (
                categories.map((category, index) => {
                  const canBeDragged = category.movable;

                  return (
                    <Draggable
                      draggableId={category.id}
                      index={index}
                      key={category.id}
                      type="category"
                      isDragDisabled={!canBeDragged}
                    >
                      {provided => (
                        <div
                          className="row"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <CategoryComponent
                            category={category}
                            onUpdate={updateCategory}
                            onDelete={deleteCategory}
                            onBeverageAdd={addBeverage}
                            onBeverageUpdate={updateBeverage}
                            onBeverageDelete={deleteBeverage}
                            beverages={constructArrayFromIdsAndHash(
                              category.beverageIds,
                              menuItems
                            )}
                            dragHandleProps={provided.dragHandleProps}
                            canBeDragged={canBeDragged}
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })
              ) : (
                <p className="text-center">
                  {type === BEVERAGE_TYPES.DRINK
                    ? t('emptyDrinksList')
                    : t('emptyCocktailsList')}
                </p>
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>

        {/*
           This silly conditional rendering below may look redundant,
            but it's an important hotfix.

           For some reason if we're using the same component for both types,
           useSearch inside keeps the searchPool from the first type we visited
           and uses it for both cases. It's definitely not what we want.
         */}

        {type === BEVERAGE_TYPES.DRINK && (
          <AddCategoryForm
            type={type}
            onSubmit={addCategory}
            searchPool={categoriesSearchPool[type]}
          />
        )}

        {type === BEVERAGE_TYPES.COCKTAIL && (
          <AddCategoryForm
            type={type}
            onSubmit={addCategory}
            searchPool={categoriesSearchPool[type]}
          />
        )}
      </DragDropContext>

      {Boolean(categories.length) && (
        <BannerSelector type={type} banners={banners} />
      )}
    </div>
  );
}
