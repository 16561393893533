import { BEVERAGE_TYPES } from './constants';
import { isActuallyNaN } from './isActuallyNaN';

const MAX_PRICE = 99999;

export const validate = entityType => {
  if (entityType === BEVERAGE_TYPES.PHOTO_COCKTAIL) {
    return ({ price }) =>
      !(price && (parseFloat(price) > MAX_PRICE || isActuallyNaN(price)));
  }

  if (entityType === BEVERAGE_TYPES.DRINK) {
    return ({ name, amount, price }) => {
      if (!name) return false;

      if (name.length > 100 || (amount && amount.length > 50)) return false;

      if (name.split('\n').length > 5) return false;

      if (price && (parseFloat(price) > MAX_PRICE || isActuallyNaN(price)))
        return false;

      return true;
    };
  }

  if (entityType === BEVERAGE_TYPES.COCKTAIL) {
    return ({ name, ingredients, price }) => {
      if (!name || !ingredients) return false;

      if (name.length > 100 || (ingredients && ingredients.length > 300)) return false;

      if (name.split('\n').length > 5 || (ingredients && ingredients.split('\n').length > 5))
        return false;

      if (price && (parseFloat(price) > MAX_PRICE || isNaN(Number(price))))
        return false;

      return true;
    };
  }

  return () => true;
};
