import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useLoader from '../hooks/useLoader';

export const AppearanceInputField = ({ field, init, onSubmit }) => {
  const [temp, setTemp] = useState(init);

  const loader = useLoader();

  const { t } = useTranslation();

  const labelText = t(field);

  const id = `template__${field}`;

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      loader.startLoading();
      onSubmit(temp);
      loader.stopLoading();
    } catch (err) {
      console.error(err);
      loader.setError(err);
    }
  };

  const submittable = !loader.loading && temp.length < 300;

  const hasChanged = temp !== init;

  const handleCancel = () => {
    setTemp(init);
  };

  return (
      <form className="input-group mb-3" onSubmit={handleSubmit}>
        <div className="input-group-prepend">
          <label className="input-group-text" htmlFor={id}>
            {labelText}
          </label>
        </div>
        <input
          type="text"
          className="form-control"
          value={temp}
          onChange={event => {
            const { value } = event.target;
            setTemp(value);
          }}
          id={id}
        />

        {hasChanged && (
          <div className="d-flex">
            <button
              className="btn btn-sm btn-primary ml-2"
              disabled={!submittable}
            >
              <i className="fa fa-check fa-fw" />
            </button>
            <button
              type="button"
              className="btn btn-sm btn-warning ml-2"
              onClick={handleCancel}
            >
              <i className="fa fa-times fa-fw" />
            </button>
          </div>
        )}
      </form>
  );
};
