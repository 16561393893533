export default function checkOverflow(element, fixClientHeightOnIpad) {
  //
  //
  let currentOverflow = element.style.overflow;

  if (!currentOverflow || currentOverflow === 'visible')
    element.style.overflow = 'hidden';

  let overflows =
    element.clientHeight < element.scrollHeight ||
    element.clientWidth < element.scrollWidth;

  element.style.overflow = currentOverflow;

  if (fixClientHeightOnIpad) {
    overflows =
      fixClientHeightOnIpad < element.scrollHeight ||
      element.clientWidth < element.scrollWidth;
  }

  return overflows;
}
