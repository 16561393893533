import axios from 'axios';
import { snake } from 'kitsu-core';
import { BANNER, BEVERAGE_TYPES } from '../lib/constants';
import { getBaseURL } from '../lib/getBaseURL';
import Banner from '../models/Banner';
import Category from '../models/Category';
import Cocktail from '../models/Cocktail';
import Drink from '../models/Drink';
import PhotoCocktail from '../models/PhotoCocktail';
import { useConfigContext } from './useConfigContext';
import consumer from "../../../channels/consumer";
import saveAs from "file-saver"

const baseURL = getBaseURL();

export default function useAPI() {
  const { drinkMenu, setDrinkMenu } = useConfigContext();

  const axiosInstance = axios.create({
    baseURL,
    timeout: 10000,
    headers: {
      'Content-Type': 'application/vnd.api+json',
    },
  });

  const createItem = async (item, category) => {
    const requestData = item.toRequest(drinkMenu, category);
    const res = await axiosInstance.post(`${item.type}_items`, requestData);

    if (!res.data) throw Error('API: No data in response');

    const { id } = res.data.data;

    if (item.type === BEVERAGE_TYPES.PHOTO_COCKTAIL) {
      const { image_path: imagePath, image_preview_path: imagePreviewPath } = res.data.data.attributes;
      if (imagePath && imagePreviewPath) {
        return new PhotoCocktail({ ...item, imagePath, imagePreviewPath, id });
      } else {
        return new PhotoCocktail({ ...item, id });
      }
    }

    if (item.type === BEVERAGE_TYPES.COCKTAIL) {
      return new Cocktail({ ...item, id });
    }

    if (item.type === BEVERAGE_TYPES.DRINK) {
      return new Drink({ ...item, id });
    }

    if (item.type === BANNER) {
      return new Banner({ ...item, id });
    }
  };

  const updateItem = async (beverage, category) => {
    const requestData = beverage.toRequest(drinkMenu, category);
    await axiosInstance.patch(
      `${beverage.type}_items/${beverage.id}`,
      requestData
    );
  };

  const deleteItem = ({ id, type }) =>
    axiosInstance.delete(`${type}_items/${id}`);

  const createCategory = async category => {
    const requestData = category.toRequest(drinkMenu);
    const res = await axiosInstance.post(
      `${category.type}_menu_categories`,
      requestData
    );

    if (!res.data) throw Error('API: No data in response');

    const { id } = res.data.data;
    return new Category({ ...category, id });
  };

  const updateCategory = async category => {
    const requestData = category.toRequest(drinkMenu);
    await axiosInstance.patch(
      `${category.type}_menu_categories/${category.id}`,
      requestData
    );
  };

  const deleteCategory = ({ id, type }) =>
    axiosInstance.delete(`${type}_menu_categories/${id}`);

  const updateDrinkMenuAttributes = async attributes => {
    attributes = Object.fromEntries(
      Object.entries(attributes).map(([key, value]) => [snake(key), value])
    );

    const requestData = {
      data: {
        type: 'drink_menu',
        attributes,
      },
    };

    const res = await axiosInstance.patch(`drink_menus/${drinkMenu.id}`, requestData);

    return res;
  };

  const updateDrinkMenuLogo = async (blobId) => {
    const res = await updateDrinkMenuAttributes({logo: blobId});

    if (!res.data) throw Error('API: No data in response');

    setDrinkMenu(old => ({...old, logoPath: res.data.data.attributes.logo_path }));
  };

  const requestDrinkMenuPDF = async () => {
    await axiosInstance.get(`drink_menus/${drinkMenu.id}.pdf`, {baseURL: '/'});
  }

  const requestPrefilledDrinkMenu = async () => {
    const requestData = {
      data: {
        type: 'drink_menu',
        attributes: {
          prefill: 'jack_daniels',
        }
      }
    }

    await axiosInstance.patch(`drink_menus/${drinkMenu.id}?include=items,menu_categories`, requestData);
  }

  return {
    createItem,
    updateItem,
    deleteItem,

    createCategory,
    updateCategory,
    deleteCategory,

    requestDrinkMenuPDF,

    requestPrefilledDrinkMenu,

    updateDrinkMenuAttributes,
    updateDrinkMenuLogo,
  };
}
