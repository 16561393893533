import styleVariables from '../../../stylesheets/variables.scss';

export const createableSelectStyles = {
  option: (providedStyles, state) => {
    // if you need to apply specific styles if the user is 'hovering' over this
    // element, use state.isFocused

    if (state.data.__isNew__) {
      // styles for 'create' options
      return {
        ...providedStyles,
        color: 'white',
        backgroundColor: styleVariables.primary,
      };
    }

    return providedStyles;
  },
};