import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useAPI from '../hooks/useAPI';

export const PDFDownloadButton = () => {
  const { t } = useTranslation();

  const { requestDrinkMenuPDF } = useAPI();

  const [cookingPDF, setCookingPDF] = useState(false);

  useEffect(() => {
    const disableCookingPDF = () => {
      console.log('async download start');
      setCookingPDF(false);
    };

    document.body.addEventListener('async-download:start', disableCookingPDF);

    return () => {
      document.body.removeEventListener('async-download:start', disableCookingPDF);
    }

  }, []);

  const handleClick = async event => {
    event.preventDefault();

    try {
      await requestDrinkMenuPDF();
      setCookingPDF(true);
    } catch (error) {
      console.error(error);
    }

  };

  return (
    <button className={`btn btn-info ${cookingPDF ? 'disabled' : ''}`} onClick={handleClick}>
      <i className="fa fa-fw fa-file-pdf mr-1"/>
      {cookingPDF ? t('preparingPDFForDownload') : t('printablePDF')}
    </button>
  );
};