import React, { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import CreateableSelect from 'react-select/creatable/dist/react-select.esm';
import useLoader from '../hooks/useLoader';
import useSearchPoolContext from '../hooks/useSearchPoolContext';
import { useStateWithReset } from '../hooks/useStateWithReset';
import { BEVERAGE_TYPES } from '../lib/constants';
import { focusOnRef } from '../lib/focusOnRef';
import { generateCreationLabel } from '../lib/generateCreationLabel';
import { createableSelectStyles } from '../lib/styles';
import { validate } from '../lib/validate';
import { wrapModelObjectWithSearchOption } from '../lib/wrapModelObjectWithSearchOption';
import Cocktail from '../models/Cocktail';
import uuid from '../utils/uuid';

export function AddCocktailForm({ onSubmit, category }) {
  const [searching, setSearching, resetSearching] = useStateWithReset(false);

  const [selectedOption, setSelectedOption, resetSelectedOption] = useStateWithReset(null);

  const [ingredients, setIngredients, resetIngredients] = useStateWithReset('');

  const [price, setPrice, resetPrice] = useStateWithReset('');

  const { beveragesSearchPool } = useSearchPoolContext();

  const { t } = useTranslation();

  const ingredientsInputRef = useRef();
  const priceInputRef = useRef();

  const searchOptions = useMemo(
    () =>
      beveragesSearchPool[BEVERAGE_TYPES.COCKTAIL]
        .filter(beverage =>
          category.originId ? beverage.category === category.originId : true
        )
        .map(wrapModelObjectWithSearchOption),
    [beveragesSearchPool, category]
  );

  const loader = useLoader();

  const handleSelectFromSearch = (selected, { action }) => {
    if (!['select-option', 'create-option'].includes(action)) return;

    setSelectedOption(selected);
  };

  useEffect(() => {
    if (!selectedOption) return;

    const { data, value } = selectedOption;

    if (!value) return;

    if (!data) {
      focusOnRef(ingredientsInputRef);
      return;
    }

    setIngredients(data.ingredients);

    focusOnRef(priceInputRef);
  }, [selectedOption]);

  const fullReset = () => {
    resetSearching();

    resetSelectedOption();

    resetIngredients();
    resetPrice();
  };

  const handleIngredientsChange = event => {
    const { value } = event.target;

    setIngredients(value);
  };

  const handlePriceChange = event => {
    const { value } = event.target;

    setPrice(value);
  };

  const handleSubmit = async event => {
    event.preventDefault();

    const { value, data, __isNew__: isNew } = selectedOption;

    const temp = isNew
      ? { name: value, position: null, ingredients, price }
      : { ...data, ingredients, price };

    const cocktail = new Cocktail({
      ...temp,
      required: false,
      id: uuid(),
    });

    try {
      loader.startLoading();

      await onSubmit(cocktail);
      fullReset();

      setSearching(true);

      loader.stopLoading();
    } catch (e) {
      window.alert(t('itemUpdateError'));
      console.error(e);
      loader.setError(e);
    }
  };

  const submittable = searching && selectedOption && selectedOption.value && !loader.loading && validate(BEVERAGE_TYPES.COCKTAIL)({name: selectedOption.label, ingredients, price});

  if (searching) {
    return (
      <form className="form-row">
        <div className="form-group col-12">
          <CreateableSelect
            autoFocus
            openMenuOnFocus
            value={selectedOption}
            placeholder={t('name')}
            onChange={handleSelectFromSearch}
            options={searchOptions}
            innerProps={{
              // necessary to prevent form submission when selecting with Enter
              onKeyPress: event => event.preventDefault(),
            }}
            noOptionsMessage={() => t('nothingFound')}
            formatCreateLabel={generateCreationLabel(t('createCocktail'))}
            styles={createableSelectStyles}
          />
        </div>

        <div className="form-group col-12 col-lg-6">
          <textarea
            ref={ingredientsInputRef}
            placeholder={t('ingredients')}
            className="form-control"
            rows={1}
            value={ingredients}
            onChange={handleIngredientsChange}
          />
        </div>

        <div className="form-group col-6 col-lg-2">
          <input
            type="number"
            className="form-control"
            placeholder={t('price')}
            value={price}
            onChange={handlePriceChange}
            ref={priceInputRef}
          />
        </div>

        <div className="form-group col-6 col-lg-4">
          <div className="btn-group form-control border-0 p-0">
            <button
              className="btn btn-primary"
              onClick={handleSubmit}
              disabled={!submittable}
            >
              <i className="fa fa-plus fa-fw mr-1" />
              {t('add')}
            </button>
            <button className="btn btn-warning" onClick={fullReset}>
              <i className="fa fa-times fa-fw mr-1" />
              {t('cancel')}
            </button>
          </div>
        </div>
      </form>
    );
  }

  return (
    <button
      className="btn btn-primary"
      onClick={() => setSearching(true)}
    >
      <span className="fa fa-fw fa-plus mr-2" />
      {t('addCocktail')}
    </button>
  );
}
