import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useEditingContext } from '../hooks/useEditingContext';
import useLoader from '../hooks/useLoader';
import { BEVERAGE_TYPES } from '../lib/constants';
import { focusOnRef } from '../lib/focusOnRef';
import { validate } from '../lib/validate';
import Cocktail from '../models/Cocktail';

export default function CocktailComponent({
  item,
  onUpdate,
  onDelete,
  dragHandleProps,
}) {
  const loader = useLoader();

  const { t } = useTranslation();

  const { id, required, movable } = item;

  const priceInputRef = useRef();

  const {
    editedEntity,
    setEditedEntity,
    resetEditedEntity,
  } = useEditingContext();

  const isEdited = editedEntity === item;

  const [temp, setTemp] = useState({ ...item });

  const getFieldId = field => `${field}-${id}`;

  const handleUpdate = async event => {
    event.preventDefault();

    try {
      loader.startLoading();

      await onUpdate(new Cocktail(temp));

      loader.stopLoading();
    } catch (e) {
      loader.setError(e);
    }

    resetEditedEntity();
  };

  const handleDelete = async event => {
    event.preventDefault();

    try {
      loader.startLoading();

      await onDelete(item);
    } catch (e) {
      console.error(e);
      window.alert(t('itemDeletionError'));
      loader.setError(e);
    }
  };

  const handleCancel = event => {
    event.preventDefault();

    setTemp({ ...item });
    resetEditedEntity();
  };

  const updateTempField = (field, value) =>
    setTemp(old => ({ ...old, [field]: value }));

  const submittable =
    isEdited &&
    Boolean(temp.name) &&
    !loader.loading &&
    validate(BEVERAGE_TYPES.COCKTAIL)(temp);

  const nameEditable = item.required;

  useEffect(() => {
    if (isEdited) focusOnRef(priceInputRef);
  }, [isEdited]);

  if (isEdited) {
    return (
      <form className="form-row">
        <div className="form-group col-12">
          <label htmlFor={getFieldId('name')}>
            {t('name')}
            {nameEditable && <>&nbsp;({t('cannotChange')})</>}
          </label>

          <textarea
            className="form-control"
            id={getFieldId('name')}
            rows={1}
            value={temp.name}
            onChange={e => updateTempField('name', e.target.value)}
            readOnly={nameEditable}
          />
        </div>

        <div className="form-group col-12 col-lg-6">
          <label htmlFor={getFieldId('ingredients')}>{t('ingredients')}</label>

          <textarea
            className="form-control"
            id={getFieldId('ingredients')}
            rows={1}
            onChange={e => updateTempField('ingredients', e.target.value)}
            value={temp.ingredients}
          />
        </div>

        <div className="form-group col-6 col-lg-3">
          <label htmlFor={getFieldId('price')}>{t('price')}</label>

          <input
            ref={priceInputRef}
            min={0}
            max={1000000}
            type="number"
            className="form-control"
            id={getFieldId('price')}
            onChange={e => updateTempField('price', e.target.value)}
            value={temp.price}
          />
        </div>

        <div className="form-group col-6 col-lg-3 d-flex align-items-end">
          <div className="btn-group form-control p-0 border-0">
            <button
              title={t('confirm')}
              disabled={!submittable}
              className="btn btn-sm btn-primary"
              onClick={handleUpdate}
            >
              <i className="fa fa-check fa-fw" />
            </button>

            <button title={t('close')} className="btn btn-sm btn-warning" onClick={handleCancel}>
              <i className="fa fa-times fa-fw" />
            </button>

            {movable && (
              <button title={t('delete')} className="btn btn-sm btn-danger" onClick={handleDelete}>
                <i className="fa fa-trash fa-fw" />
              </button>
            )}
          </div>
        </div>
      </form>
    );
  }

  const { ingredients, formattedPrice, name } = item;

  return (
    <div className="row" onClick={() => setEditedEntity(item)}>
      <div className="col-10 col-lg-11 hover-underline pointer">
        <div className="row">
          <div className="col-9">
            <div className="prewrap lead mb-2">{name}</div>
            <div className="prewrap">{ingredients}</div>
          </div>

          <div className="col-3">
            {formattedPrice || (
                <button className="btn btn-secondary btn-sm">{t('enterPrice')}</button>
            )}
          </div>
        </div>
      </div>

      {!required && (
        <div
          className="col lead d-flex align-items-center justify-content-end"
          {...dragHandleProps}
        >
          <i className="fa fa-arrows-alt fa-fw" />
        </div>
      )}
    </div>
  );
}
