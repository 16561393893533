import React, {useMemo} from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { BEVERAGE_TYPES } from '../lib/constants';
import { AddCocktailForm } from './AddCocktailForm';
import { AddDrinkForm } from './AddDrinkForm';
import './CategoryComponent.scss';
import { CategoryHeader } from './CategoryHeader';
import CocktailComponent from './CocktailComponent';
import DrinkComponent from './DrinkComponent';

export default function CategoryComponent({
  category,
  onDelete,
  onUpdate,
  onBeverageAdd,
  onBeverageUpdate,
  onBeverageDelete,
  beverages,
  dragHandleProps,

  canBeDragged,
}) {
  const { type } = category;

  const { t } = useTranslation();

  const handleAddBeverage = beverage => onBeverageAdd(beverage, category);

  const handleUpdateBeverage = beverage => onBeverageUpdate(beverage, category);

  const handleBeverageDelete = beverage => onBeverageDelete(beverage, category);

  // category being "required" means it contains required items
  const required = useMemo(() => Boolean(beverages.filter(({required}) => required).length), [beverages]);

  // allow deletion only on empty categories
  const deleteable = !beverages.length;

  const editable = !required;

  const dragDropType = `beverages-${category.id}`;

  return (
    <div className="col-12">
      <div className="card mb-4 category-component">
        <CategoryHeader
          editable={editable}
          deleteable={deleteable}
          category={category}
          onCategoryDelete={onDelete}
          onCategoryUpdate={onUpdate}
          dragHandleProps={dragHandleProps}

          canBeDragged={canBeDragged}
        />

        <Droppable type={dragDropType} droppableId={category.id}>
          {provided =>
            beverages.length ? (
              <div
                className="list-group-flush"
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {beverages.map((beverage, index) => (
                  <Draggable
                    draggableId={beverage.id}
                    index={index}
                    key={beverage.id}
                    type={dragDropType}
                    isDragDisabled={beverage.required}
                  >
                    {provided => (
                      <div className="list-group-item" ref={provided.innerRef} {...provided.draggableProps}>
                        {type === BEVERAGE_TYPES.COCKTAIL ? (
                          <CocktailComponent
                            key={beverage.id}
                            item={beverage}
                            onUpdate={handleUpdateBeverage}
                            onDelete={handleBeverageDelete}
                            dragHandleProps={provided.dragHandleProps}
                          />
                        ) : (
                          <DrinkComponent
                            key={beverage.id}
                            item={beverage}
                            onUpdate={handleUpdateBeverage}
                            onDelete={handleBeverageDelete}
                            dragHandleProps={provided.dragHandleProps}
                            category={category}
                          />
                        )}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            ) : (
              <div
                className="card-body border-left border-right"
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                <p className="btn d-flex justify-content-center mb-0">
                  {t('emptyCategory')}
                </p>
              </div>
            )
          }
        </Droppable>

        <div className="card-footer bg-gray-200 border-0">
          {type === BEVERAGE_TYPES.COCKTAIL && (
            <AddCocktailForm onSubmit={handleAddBeverage} category={category} />
          )}

          {type === BEVERAGE_TYPES.DRINK && (
            <AddDrinkForm onSubmit={handleAddBeverage} category={category} />
          )}
        </div>
      </div>
    </div>
  );
}
