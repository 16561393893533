import { BEVERAGE_TYPES, BLOCK_FORMATS, EMPTY_BLOCK } from '../lib/constants';
import React, { useMemo, useState } from 'react';

import Banner from '../models/Banner';
import BannerComponent from './BannerComponent';
import pluralize from 'pluralize';
import { useBlocksContext } from '../hooks/useBlocksContext';
import { useConfigContext } from '../hooks/useConfigContext';
import useLoader from '../hooks/useLoader';
import useMenuContext from '../hooks/useMenuContext';
import useSearchPoolContext from '../hooks/useSearchPoolContext';
import { useTranslation } from 'react-i18next';

const { COCKTAIL, DRINK, PHOTO_COCKTAIL } = BEVERAGE_TYPES;

export const getSectionNameForType = type => {
  let sectionName = type;

  if (Object.values(BEVERAGE_TYPES).includes(type))
    sectionName = pluralize.plural(type);

  return sectionName;
};

const SECTIONS = Object.freeze(
  [...Object.values(BEVERAGE_TYPES), EMPTY_BLOCK].map(getSectionNameForType)
);

export default function BannerSelector({ type, banners: selectedBanners }) {
  const loader = useLoader();

  const { t } = useTranslation();

  const [choosing, setChoosing] = useState(false);

  const { deleteBanner, addBanner } = useMenuContext();

  const { unfitSearchPoolBanners, unfitBanners } = useBlocksContext();

  const { bannersSearchPool } = useSearchPoolContext();

  const { selectedTitlePage, selectedBlockFormat } = useConfigContext();

  const searchPool = useMemo(() => {
    const usingA5 = selectedBlockFormat === BLOCK_FORMATS.A5;

    if (type === PHOTO_COCKTAIL) type = COCKTAIL;

    let fittingBanners = bannersSearchPool.filter(
      banner =>
        !unfitSearchPoolBanners.some(
          b => b.id === banner.id && b.section === getSectionNameForType(type)
        )
    );

    const [oneColumnBanners, fullWidthBanners] = fittingBanners.reduce(
      (result, banner) => {
        result[Number(banner.fullWidth)].push(banner);

        return result;
      },
      [[], []]
    );

    if (type === DRINK) {
      // for drinks, show oneColumn banners only if none of the fullWidth banners fit
      fittingBanners = fullWidthBanners.length
        ? fullWidthBanners
        : oneColumnBanners;
    }

    // on A5, empty block should only allow full-width banners
    if (usingA5 && type === EMPTY_BLOCK) {
      fittingBanners = fullWidthBanners;
    }

    const maxImageHeight = fittingBanners.reduce(
      (res, { imageHeight }) => (imageHeight > res ? imageHeight : res),
      -1
    );

    // only allow banners which have the maximum available height
    fittingBanners = fittingBanners.filter(
      (banner) =>
        banner.imageHeight === maxImageHeight ||
        banner.imageHeight + 1 === maxImageHeight ||
        banner.imageHeight - 1 === maxImageHeight
    );

    return fittingBanners;
  }, [bannersSearchPool, unfitSearchPoolBanners, type, selectedBlockFormat]);

  // prohibit selecting banners on honey since it has a light background
  // if (selectedTitlePage.variant === 'honey') return null;

  if (!selectedBanners.length && !searchPool.length) return null;

  const handleSelectBanner = async banner => {
    try {
      loader.startLoading();

      const section = getSectionNameForType(type);

      const tempBanner = new Banner({
        ...banner,
        section,
      });

      setChoosing(false);

      await addBanner(tempBanner);

      loader.stopLoading();
    } catch (e) {
      console.error(e);
      loader.setError(e);
    }
  };

  const canStartChoosing = !choosing && searchPool.length;

  return (
    <div className="row justify-content-center">
      <div className="col-12 col-lg-8">
        <hr />

        {!choosing && (
          <div className="row">
            <div className="col-12 btn-group" role="group">
              <button
                className="btn btn-outline-dark btn-sm"
                onClick={() => setChoosing(true)}
                disabled={!canStartChoosing}
              >
                <i className="fa fa-plus mr-2" />
                {t('addBanner')}
              </button>
            </div>
          </div>
        )}

        {choosing && (
          <>
            <div className="row">
              <div className="col-12 btn-group" role="group">
                <button
                  className="btn btn-outline-dark btn-sm"
                  onClick={() => setChoosing(false)}
                >
                  <i className="fa fa-times mr-2" />
                  {t('hideBanner')}
                </button>
              </div>
            </div>
            <div className="row">
              {searchPool.map(banner => (
                <div className="col-4" key={banner.id}>
                  <BannerComponent
                    banner={banner}
                    onClick={() => handleSelectBanner(banner)}
                  />
                </div>
              ))}
            </div>
          </>
        )}
        <div className="row justify-content-center">
          {selectedBanners.map(banner => {
            const unfit = unfitBanners.includes(banner);

            if (unfit) return deleteBanner(banner) && null;

            return (
              <div className="col-4" key={banner.id}>
                <BannerComponent banner={banner} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
