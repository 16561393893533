import React, { useMemo, useState } from 'react';

import { BLOCK_FORMATS } from '../lib/constants';
import monetize from '../utils/monetize';
import { useBlocksContext } from '../hooks/useBlocksContext';
import { useConfigContext } from '../hooks/useConfigContext';
import useDataContext from '../hooks/useDataContext';
import { useLibraryContext } from '../hooks/useLibraryContext';
import { useTranslation } from 'react-i18next';

export const MIN_QUANTITY = 20;
export const MAX_QUANTITY = 150;

export const OrderPriceCalculator = ({
  settingPrice,
  value: quantity,
  onChange: setQuantity,
}) => {
  const { t } = useTranslation();

  const { priceList } = useDataContext();
  const { productsLibrary } = useLibraryContext();

  const { priceListItems } = priceList;

  const { selectedBlockFormat, selectedMaterial } = useConfigContext();
  const { blockCount } = useBlocksContext();

  const productsNestedUnderBlockFormatAndBlockCount = useMemo(() => {
    return Object.values(productsLibrary).reduce(
      (result, product) => {
        const { blockFormat, blockCount, material } = product;

        const priceItemsForProduct = priceListItems.filter(
          item => item.product.id === product.id
        );

        const quantitiesToPrices = Object.fromEntries(
          priceItemsForProduct.map(item => [
            item.quantity,
            parseFloat(item.unit_price),
          ])
        );

        // return null;

        let quantitiesToUnitPrices = {};

        let currentPrice = null;

        for (
          let quantity = MIN_QUANTITY;
          quantity <= MAX_QUANTITY;
          quantity++
        ) {
          if (quantitiesToPrices[quantity])
            currentPrice = quantitiesToPrices[quantity];

          quantitiesToUnitPrices[quantity] = currentPrice;
        }

        result[blockFormat][blockCount] = result[blockFormat][blockCount] || {};
        result[blockFormat][blockCount][material] = quantitiesToUnitPrices;

        return result;
      },
      Object.keys(BLOCK_FORMATS).reduce(
        (result, blockFormat) => ({ ...result, [blockFormat]: {} }),
        {}
      )
    );
  }, [productsLibrary]);

  const [quantityInputValue, setQuantityInputValue] = useState(quantity);

  const handleChange = event => {
    const { value } = event.target;

    setQuantityInputValue(value);

    // const newQuantity = Number.min(Number(value), );
    const { min, max } = Math;

    const newQuantity = min(max(Number(value), MIN_QUANTITY), MAX_QUANTITY);
    setQuantity(newQuantity);
  };

  const handleBlur = () => {
    setQuantityInputValue(quantity);
  };

  const pricePerMenu =
    (productsNestedUnderBlockFormatAndBlockCount[selectedBlockFormat] &&
      productsNestedUnderBlockFormatAndBlockCount[selectedBlockFormat][
        blockCount
      ] &&
      productsNestedUnderBlockFormatAndBlockCount[selectedBlockFormat][
        blockCount
      ][selectedMaterial] &&
      productsNestedUnderBlockFormatAndBlockCount[selectedBlockFormat][
        blockCount
      ][selectedMaterial] &&
      productsNestedUnderBlockFormatAndBlockCount[selectedBlockFormat][
        blockCount
      ][selectedMaterial][quantity]) ||
    null;

  const totalPrice = pricePerMenu ? (pricePerMenu * quantity).toFixed(2) : null;

  // const label = `${t('from')} ${MIN_QUANTITY} ${t('to')} ${MAX_QUANTITY}`;

  if (!settingPrice) return null;

  return (
    <div className="col-lg-8 col-12 mb-2">
      <div className="row">
        <div className="col-6 col-lg-3 form-group d-flex align-items-center">
          <span>{t('OrderPriceForm.quantity')}:</span>

          <input
            type="number"
            style={{ maxWidth: '100px' }}
            className="form-control ml-2"
            min={MIN_QUANTITY}
            max={MAX_QUANTITY}
            value={quantityInputValue}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>

        <div className="col-6 col-lg-3 form-group d-flex align-items-center">
          {t('material')}: {t(`AppearanceScreen.${selectedMaterial}Material`)}
        </div>

        <div className="col-6 col-lg-3 form-group d-flex align-items-center">
          {t('blockCount')}: {blockCount}
        </div>

        <div className="col-6 col-lg-3 form-group d-flex align-items-center">
          {t('OrderPriceForm.pricePerMenu')}: {monetize.format(pricePerMenu) || 'Neznamá'}
        </div>

        <div className="col-6 col-lg-3 form-group d-flex align-items-center">
          {t('totalPrice')}: {monetize.format(totalPrice) || 'Neznamá'}
        </div>
      </div>
    </div>
  );
};
