import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { useEditingContext } from '../hooks/useEditingContext';
import useLoader from '../hooks/useLoader';
import { wrapModelObjectWithSearchOption } from '../lib/wrapModelObjectWithSearchOption';
import PhotoCocktail from '../models/PhotoCocktail';

export default function AddPhotoCocktailForm({ onSubmit, searchPool, hideForm }) {
  if (hideForm) {
    return <></>;
  }

  const [searching, setSearching] = useState(false);

  const { t } = useTranslation();

  const { setEditedEntity } = useEditingContext();

  const loader = useLoader();

  const fullReset = () => {
    setSearching(false);
  };

  const handleCancel = fullReset;

  const selectOptions = useMemo(() => {
    return searchPool.map(wrapModelObjectWithSearchOption);
  }, [searchPool]);

  const handleSelectChange = async selectedOption => {
    const { data: selected } = selectedOption;

    try {
      loader.startLoading();

      const tempPhotoCocktail = new PhotoCocktail({
        ...selected,
        required: false,
      });

      const createdPhotoCocktail = await onSubmit(tempPhotoCocktail);

      setEditedEntity(createdPhotoCocktail);

      fullReset();

      loader.stopLoading();
    } catch (e) {
      console.error(e);
      loader.setError(e);
    }
  };

  return (
    <div className="row justify-content-center">
      <div className="col-12 col-lg-8">
        {searching ? (
          <div className="form-row">
            <div className="col-9">
              <Select
                autoFocus
                openMenuOnFocus

                placeholder={t('name')}
                onChange={handleSelectChange}
                options={selectOptions}
                noOptionsMessage={() => t('nothingFound')}
              />
            </div>

            <div className="col-3 d-flex justify-content-end">
              <button
                className="btn btn-warning text-nowrap w-100"
                onClick={handleCancel}
              >
                <i className="fa fa-times fa-fw mr-1" />
                {t('cancel')}
              </button>
            </div>
          </div>
        ) : (
            <button
              className="bg-primary border-0 btn text-white w-100"
              onClick={() => setSearching(true)}
            >
              <i className="fa fa-fw fa-plus mr-2" />
              {t('addPhotoCocktail')}
            </button>
        )}
      </div>
    </div>
  );
}
