import React from 'react';
import { useTranslation } from 'react-i18next';
import { SortableHandle } from 'react-sortable-hoc';
import './PhotoCocktailComponent.scss';
import useLoader from '../hooks/useLoader';
import PhotoCocktail from '../models/PhotoCocktail';
import { PhotoCocktailPriceForm } from './PhotoCocktailPriceForm';

const SortableDragHandle = SortableHandle(() => (
  <div className="btn bg-dark text-white photo-cocktail-component__drag position-absolute">
    <i className="fa fa-arrows-alt fa-fw" />
  </div>
));

export default function PhotoCocktailComponent({
  photoCocktail,
  onDelete,
  onUpdate,
}) {
  const { t } = useTranslation();

  const {
    imagePreviewPath,
    name,
    formattedIngredients,
    required,
  } = photoCocktail;

  const loader = useLoader();

  const handleDelete = async () => {
    try {
      loader.startLoading();
      await onDelete(photoCocktail);
    } catch (e) {
      console.error(e);
      window.alert(t('itemDeletionError'));
      loader.setError(e);
    }
  };

  const handleUpdate = async price => {
    try {
      loader.startLoading();

      const newPhotoCocktail = new PhotoCocktail({
        ...photoCocktail,
        price,
      });

      await onUpdate(newPhotoCocktail);

      loader.stopLoading();
    } catch (e) {
      console.error(e);
      loader.setError(e);
      window.alert(t('itemUpdateError'));
    }
  };

  return (
    // TODO: remove overflow-hidden when proper images/styles are in place
    <div className="card photo-cocktail-component m-auto position-relative border-0 overflow-hidden">
      <div className="card-body d-flex box-shadow-inner">
        <img
          className="photo-cocktail-component__photo col"
          src={imagePreviewPath}
        />

        <div className="col d-flex flex-column justify-content-between">
          <h5 className="card-title prewrap">{name}</h5>
          <div className="prewrap h-50">{formattedIngredients.replace(/<\/?span>/g, '').replace(/&[\w]+;/g, ' ')}</div>

          <div className="h-25">
            <PhotoCocktailPriceForm
              photoCocktail={photoCocktail}
              onSubmit={handleUpdate}
            />
          </div>
        </div>
      </div>

      {!required && (
        <>
          <button
            className="btn btn-danger position-absolute photo-cocktail-component__delete mt-2"
            onClick={handleDelete}
            disabled={loader.loading}
          >
            <i className="fa fa-trash fa-fw" />
          </button>

          {/*<SortableDragHandle />*/}
        </>
      )}
    </div>
  );
}
