export default function materialForm() {
  document.querySelectorAll('.form-control:not(select)[placeholder]').forEach(item => {
    if (item.nextElementSibling) {
      item.nextElementSibling.classList.add('active');
    }
  });

  document.querySelectorAll('.form-control:not([placeholder])').forEach(item => {
    if (item.nextElementSibling) {
      item.nextElementSibling.classList.toggle('active', item.value.length > 0);
      let isActive = false;
      item.addEventListener('keyup', event => {
        const elem = event.target;
        if (!isActive) {
          elem.nextElementSibling.classList.toggle('active', elem.value.length > 0);
        }
      });

      item.addEventListener('focus', event => {
        const elem = event.target;
        if (elem.value.length === 0) {
          isActive = true;
          elem.nextElementSibling.classList.add('active');
        }
      });

      item.addEventListener('blur', event => {
        const elem = event.target;
        if (elem.value.length === 0) {
          isActive = false;
          elem.nextElementSibling.classList.remove('active');
        }
      });
    }
  });
}
