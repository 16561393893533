import './InfoTourBubble.scss';

import React, { useEffect, useState } from 'react';

import InfoTourContext from '../contexts/InfoTourContext';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  bubbleName: string;
  text: string;
  className: string;
  btnText?: string;
}

export default function InfoTourBubble({
  bubbleName,
  text,
  className,
  btnText,
}: Props) {
  const {
    nextTourBubble,
    closeInfoTour,
    prevTourBubble,
    activeBubbleName,
    runningInfoTour,
  } = useContext(InfoTourContext);
  const { t } = useTranslation();
  const [btnNextState, setBtnNextState] = useState(t('next'));
  useEffect(() => {
    if (runningInfoTour === 'EMPTY_PAGE' && activeBubbleName === 'RULES') {
      setBtnNextState(t('close'));
    } else {
      setBtnNextState(t('next'));
    }
  }, [activeBubbleName, runningInfoTour]);

  return (
    <>
      {activeBubbleName === bubbleName && activeBubbleName !== '' && (
        <div
          className={`flex direction-column info-tour-bubble-default ${className}`}
        >
          <div className="escape-bubble-wrap" onClick={closeInfoTour}>
            <i className="fa fa-times fa-fw" />
          </div>
          <div className="p-1 bubble-text">{text}</div>

          <div className="btns mt-4">
            <button className="btn mr-3" onClick={prevTourBubble}>
              {t('back')}
            </button>
            <button className="btn btn-primary" onClick={nextTourBubble}>
              {btnText || btnNextState}
            </button>
          </div>
        </div>
      )}
    </>
  );
}
