import { BEVERAGE_TYPES_ENUM, BLOCK_FORMATS } from '../lib/constants';
import {
  createColumn,
  createSimulator,
  simulateDrinkBlock,
} from '../Renderer/helpers';

import { BeveragesAndCategory } from '../types/BeverageTypes';
import Category from '../models/Category';
import DOMNodeWrapper from '../models/DOMNodeWrapper';
import { ITEMS_IN_CATEGORY_BEFORE_PAGE_BREAK } from './paginatePhotoCocktailsAndCocktailCategories';
import checkOverflow from '../lib/checkOverflow';

// the goal here is to take categories as models
// and return a flat array of DOM nodes,
// where a node can either be a: category title OR list item (drink, cocktail)

/*
The main idea of this algorithm is the following:
Given a flat array of DOM nodes (category titles OR drink/cocktail items)
go through that array and:
    IF we see a title, try to group it with up to ITEMS_IN_CATEGORY_BEFORE_PAGE_BREAK items after it,
                         if these items are drink/cocktail items
    Such group is a HUNK:
    [title, item, item, item]
    For items which don't fall into this grouping rule,
    just pack them into single-item arrays. These are also HUNKS.
    [item]
    This is needed for consistency, because we will then merge these two
    together into a nested array, and will be able to render these items
    according to consistent rules.
    An array of _hunks_ may look something like this:
    [
      [title, item, item, item],
      [item],
      [item],
      [title, item],        <-- if a category contains less than ITEMS_IN_CATEGORY_BEFORE_PAGE_BREAK items,
      [title, item, item],  <-- group only them, like so.
      [item],
      [item],
      etc...
    ]
    We will then paginate them similarly to how it's done with the photo
    cocktails (through barbarically stuffing a block full of items, then
    checking if it overflows, offloading the last item to the new block,
    and trying again).
 */

export default function paginateDrinkCategories(
  categories: Category[],
  type: string,
  blockFormat?: string,
  pseudoTitleBlock?: HTMLDivElement
) {
  // throw away item-less categories if such were given
  categories = [...categories].filter(category => category?.items?.length);

  let blockifiedDrinkCategories: BeveragesAndCategory[][] = [];

  let dOMNodeWrapperArr: DOMNodeWrapper[][] = [];

  if (!categories?.length) {
    return {
      blockifiedDrinkCategories,
    };
  }

  const paginatingDrinksOnA5 =
    blockFormat === BLOCK_FORMATS.A5 && type === BEVERAGE_TYPES_ENUM.DRINK;

  const drinksOnA4 =
    blockFormat === BLOCK_FORMATS.A4 && type === BEVERAGE_TYPES_ENUM.DRINK;

  // init the simulator

  const simulator = createSimulator(blockFormat);

  document.body.appendChild(simulator);

  const hunks = categories.reduce(
    (res: BeveragesAndCategory[][], category: Category) => {
      const hunkedCategory = category.items
        // .map(item => item.domNode) // convert everything to DOM nodes
        .reduce(
          (
            res: BeveragesAndCategory[][],
            item: BeveragesAndCategory,
            index: number
          ) => {
            if (index < ITEMS_IN_CATEGORY_BEFORE_PAGE_BREAK) {
              res[0].push(item);
            } else {
              res.push([item]);
            }

            return res;
          },
          // 2D array. 1st array will be for [title, item, item]
          //           2nd onwards will be just [item], [item]....
          [[category]]
        );

      return [...res, ...hunkedCategory];
    },
    []
  );

  let index = 0;
  const blockfulsOfCategoryHunks = [hunks];

  /*
   Last category title we encountered during the iterations.
   Is duplicated before the drinks/cocktails which were blockified away somewhere
   First element of the first hunk is most definitely a category title.
             HERE_
   hunks: [ [title, item, item], [item], ... ];
  */

  let block = simulateDrinkBlock(
    blockfulsOfCategoryHunks[index]?.flat(),
    simulator
  );

  const checkBlockOverflow = (block: HTMLDivElement) => {
    //
    const contentContainer = block.firstChild as HTMLDivElement;

    if (paginatingDrinksOnA5 || drinksOnA4) {
      const fixClientHeightOnIpadA5 = 712;
      const leftColumn = contentContainer?.firstChild as HTMLDivElement;
      const rightColumn = contentContainer?.lastChild as HTMLDivElement;


      const leftOverflow = checkOverflow(leftColumn, fixClientHeightOnIpadA5);
      const rightOverflow = checkOverflow(rightColumn, fixClientHeightOnIpadA5);

      return (leftOverflow || rightOverflow);
    }

    const fixClientHeightOnIpad = 1122;

    return checkOverflow(contentContainer, fixClientHeightOnIpad);
  };

  let lastCategoryTitle;

  let limit = 5000;

  while (checkBlockOverflow(block)) {
    if (limit < 0) {
      // how to make this error react way
      window["appError"] = 'renderError';
      break;
    }
    limit --;

    if (!blockfulsOfCategoryHunks[index + 1])
      blockfulsOfCategoryHunks[index + 1] = [];

    const lastHunkOnTheBlock = blockfulsOfCategoryHunks[index].pop();

    //

    if (lastHunkOnTheBlock) {
      blockfulsOfCategoryHunks[index + 1].unshift(lastHunkOnTheBlock); // append to the next block

      block = simulateDrinkBlock(
        blockfulsOfCategoryHunks[index].flat(),
        simulator
      );
    }

    // if after moving the item it still overflows, try next iteration
    if (checkBlockOverflow(block)) continue;

    /*
     If the next block starts with a 'lonely' item hunk prepend a category title to it.
     AND if we're on A5, the current block is even (index is odd) <- that is because we're duplicating category titles across pages
    and within this particular pagination mechanism blocks are actually columns
    which will later be united into actual 2-column blocks
     [item]  ->   [title, item]
    */

    if (
      blockfulsOfCategoryHunks[index + 1][0]?.length === 1 &&
      (!drinksOnA4 || index >= 1)
    ) {
      // Search current block's hunks from the end to the beginning for the last category heading.

      let lastHunkIndex = blockfulsOfCategoryHunks[index]?.length - 1; // last index

      // Go through the indices until we find a hunk that has more than one item.
      while (blockfulsOfCategoryHunks[index][lastHunkIndex]?.length === 1) {
        lastHunkIndex -= 1;
      }
      lastCategoryTitle =
        (blockfulsOfCategoryHunks[index][lastHunkIndex] &&
          blockfulsOfCategoryHunks[index][lastHunkIndex][0]) ||
        lastCategoryTitle;

      if (
        lastCategoryTitle &&
        (index % 2 || !paginatingDrinksOnA5) &&
        (!drinksOnA4 || index === 2)
      ) {
        blockfulsOfCategoryHunks[index + 1][0].unshift(lastCategoryTitle);
      }
    }

    // but if it helped, set block to the next and increase the index
    block = simulateDrinkBlock(
      blockfulsOfCategoryHunks[index + 1].flat(),
      simulator
    );
    index += 1;
  }

  document.body.removeChild(simulator);

  blockifiedDrinkCategories = blockfulsOfCategoryHunks.filter(item => item && item.length).map(blockfulOfHunks =>
    blockfulOfHunks.flat()
  );

  if (paginatingDrinksOnA5 || drinksOnA4) {
    /*
     All of this is necessary, because we blockified A5 drinks
     as if every column in the 2-column layout is a "block".
     Now we have to merge 2 columns into whole block.
    */

    dOMNodeWrapperArr = blockifiedDrinkCategories.reduce(
      (
        acc: DOMNodeWrapper[][],
        blockfulOfItems: BeveragesAndCategory[],
        index: number,
        arr: BeveragesAndCategory[][]
      ) => {
        const nextBlockfulOfItems = arr[index + 1];

        if (drinksOnA4) {
          if (index === 0 && pseudoTitleBlock) {
            pseudoTitleBlock?.classList.add('A4-title-page');
            const wrappedTitlePage = new DOMNodeWrapper({
              domNode: pseudoTitleBlock,
              origin: 'Column',
            });
            const secondColumn = createColumn(
              blockfulOfItems,
              'A4-title-page--right-column'
            );

            const block = [wrappedTitlePage, secondColumn];

            return [...acc, block];
          }

          if (index === 1) {
            const firstColumn = createColumn(arr[1]);
            const secondColumn = createColumn(arr[2] || []);

            const block = [firstColumn, secondColumn];
            return [...acc, block];
          }

          if (index === 3 || index === 5) {
            const firstColumn = createColumn(arr[index] || []);
            const secondColumn = createColumn(arr[index + 1] || []);

            const block = [firstColumn, secondColumn];
            return [...acc, block];
          }

          return acc;
        }

        // do nothing on odd indeces
        if (index % 2) return acc;
        const firstColumn = createColumn(blockfulOfItems);

        const secondColumn = createColumn(nextBlockfulOfItems || []);

        const block = [firstColumn, secondColumn];

        return [...acc, block];
      },
      []
    );
  }

  if (blockFormat === 'halfA4') {
    return { blockifiedDrinkCategories };
  }
  return { blockifiedDrinkCategories: dOMNodeWrapperArr };
}