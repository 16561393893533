export default function toCamelCase(str?: string | any): string {
  if (!str) return;
  const arr = str.match(/[a-z]+|\d+/gi);
  return arr.map((m, i) => {
    let low = m.toLowerCase();
    if (i != 0) {
      low = low.split('').map((s, k) => (k == 0 ? s.toUpperCase() : s)).join``;
    }
    return low;
  }).join``;
}

// console.log(toCamelCase('Foo      Bar'));
// console.log(toCamelCase('--foo-bar--'));
// console.log(toCamelCase('__FOO_BAR__-'));
// console.log(toCamelCase('foo123Bar'));
// console.log(toCamelCase('foo_Bar'));
