import React, { useContext, useMemo } from 'react';
import { BEVERAGE_TYPES } from '../lib/constants';
import Banner from '../models/Banner';
import Category from '../models/Category';
import Cocktail from '../models/Cocktail';
import Drink from '../models/Drink';
import PhotoCocktail from '../models/PhotoCocktail';
import Product from '../models/Product';
import DataContext from './DataContext';

const { COCKTAIL, PHOTO_COCKTAIL, DRINK } = BEVERAGE_TYPES;

const LibraryContext = React.createContext({});

export default LibraryContext;

export const LibraryProvider = ({ children }) => {
  const {
    drinkCategories: drinkCategoriesLibraryData,
    cocktailCategories: cocktailCategoriesLibraryData,

    drinks: drinksLibraryData,
    cocktails: cocktailsLibraryData,
    photoCocktails: photoCocktailsLibraryData,
    banners: bannersLibraryData,

    products: productsLibraryData,
  } = useContext(DataContext);

  /*
     Libraries are read-only collections of all beverages
     and categores of beverages and other entities we know exist in the database.

     These serve for lookup and pre-filling only.

     Categories, drinks and cocktails in the library should have their positions reduced by 1
     in order to align them with the array-based starting point of 0 in the menu items
   */

  const productsLibrary = useMemo(() => {
    return productsLibraryData.reduce((result, productData) => {
      result[productData.id] = new Product(productData);

      return result;
    }, {});
  }, [productsLibraryData])

  const beveragesLibrary = useMemo(function() {
    return {
      [PHOTO_COCKTAIL]: Object.fromEntries(
        photoCocktailsLibraryData.map(photoCocktailData => [
          photoCocktailData.id,
          new PhotoCocktail(photoCocktailData),
        ])
      ),

      [COCKTAIL]: Object.fromEntries(
        cocktailsLibraryData.map(cocktailData => {
          const cocktail = new Cocktail({
            ...cocktailData,
            position: cocktailData.position && cocktailData.position - 1,
          });

          // Library includes original beverages and categories as they arrived from admin.
          // Therefore, they lack originPosition attribute, which we should add here.

          cocktail.originPosition = cocktailData.position;

          return [
            cocktailData.id,
            cocktail
          ];
        })
      ),

      [DRINK]: Object.fromEntries(
        drinksLibraryData.map(drinkData => {
          const drink = new Drink({ ...drinkData, position: drinkData.position && drinkData.position - 1 });

          drink.originPosition = drinkData.position;

          return [drink.id, drink];
        })
      ),
    };
  }, []);

  const categoriesLibrary = useMemo(function() {
    const mapToHashEntries = categoryData => {
      const category = new Category({
        ...categoryData,
        position: categoryData.position && categoryData.position - 1,
      });

      category.type = category.type.split('_')[0];
      category.originId = category.id;
      category.originPosition = categoryData.position;

      return [category.id, category];
    };

    return {
      [COCKTAIL]: Object.fromEntries(
        cocktailCategoriesLibraryData.map(mapToHashEntries)
      ),
      [DRINK]: Object.fromEntries(
        drinkCategoriesLibraryData.map(mapToHashEntries)
      ),
    };
  }, []);

  const bannersLibrary = useMemo(function() {
    return Object.fromEntries(
      bannersLibraryData.map(banner => [banner.id, new Banner(banner)])
    );
  }, []);

  return (
    <LibraryContext.Provider
      value={{
        categoriesLibrary,
        beveragesLibrary,
        bannersLibrary,

        productsLibrary,
      }}
    >
      {children}
    </LibraryContext.Provider>
  );
};
