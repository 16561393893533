import React, { useState } from 'react';

const NavigationContext = React.createContext({});

export default NavigationContext;

export const NavigationProvider = ({ children }) => {
  const [currentRoute, setCurrentRoute] = useState(null);

  return (
    <NavigationContext.Provider
      value={{
        setCurrentRoute,
        currentRoute,
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
};
