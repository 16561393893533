export const checkIfLibraryItemExistsAmongMenuItems = (libraryItem, menuItems) => {
  let alreadyExistsInMenu = false;

  for (const menuItem of menuItems) {
    alreadyExistsInMenu = menuItem.name === libraryItem.name;

    if (menuItem.originId) {
      alreadyExistsInMenu =
        alreadyExistsInMenu || menuItem.originId === libraryItem.id;
    }

    if (alreadyExistsInMenu) break;
  }

  return alreadyExistsInMenu;
};