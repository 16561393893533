import { BEVERAGE_TYPES } from '../lib/constants';
import monetize from '../utils/monetize';
import Beverage from './Beverage';

export default class Drink extends Beverage {
  constructor(data) {
    super(data);
    this.variants = data.variants || [
      { amount: data.amount, price: data.price },
    ];
    this.type = BEVERAGE_TYPES.DRINK;
  }

  get domNode() {
    const node = document.createElement('div');
    node.classList.add('drink-container');

    this.variants.forEach(variant => {
      const { amount, price } = variant;
      const formattedPrice = monetize.format(price);

      const drink = document.createElement('div');
      drink.classList.add('drink');
      node.appendChild(drink);

      const nameNode = document.createElement('div');
      nameNode.classList.add('drink__name');
      nameNode.innerHTML = this.name;
      drink.appendChild(nameNode);

      const amountNode = document.createElement('div');
      amountNode.classList.add('drink__amount');
      if (amount) amountNode.innerHTML = amount;
      drink.appendChild(amountNode);

      const priceNode = document.createElement('div');
      priceNode.classList.add('drink__price');
      if (formattedPrice) priceNode.innerHTML = formattedPrice;
      drink.appendChild(priceNode);
    });

    return node;
  }

  toRequest(drinkMenu, category) {
    return {
      data: {
        type: 'drink_item',
        attributes: {
          drink_menu_id: drinkMenu.id,
          name: this.name,

          position: this.position,
          editable: this.editable,

          // we're handling this data via 'variants' now
          // price: this.price,
          // amount: this.amount,

          variants: this.variants,

          drink_menu_category_id: category.id,
          drink_id: this.originId,
          origin_position: this.originPosition,
        },
      },
    };
  }
}
