import React, { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import CreateableSelect from 'react-select/creatable';
import useLoader from '../hooks/useLoader';
import useSearchPoolContext from '../hooks/useSearchPoolContext';
import { useStateWithReset } from '../hooks/useStateWithReset';
import { BEVERAGE_TYPES } from '../lib/constants';
import { focusOnRef } from '../lib/focusOnRef';
import { generateCreationLabel } from '../lib/generateCreationLabel';
import { createableSelectStyles } from '../lib/styles';
import { validate } from '../lib/validate';
import { wrapModelObjectWithSearchOption } from '../lib/wrapModelObjectWithSearchOption';
import Drink from '../models/Drink';
import uuid from '../utils/uuid';

export function AddDrinkForm({ onSubmit, category }) {
  const [searching, setSearching, resetSearching] = useStateWithReset(false);

  const { beveragesSearchPool } = useSearchPoolContext();

  const { t } = useTranslation();

  const loader = useLoader();

  const searchOptions = useMemo(
    () =>
      beveragesSearchPool[BEVERAGE_TYPES.DRINK]
        .filter(beverage =>
          category.originId ? beverage.category === category.originId : true
        )
        .map(wrapModelObjectWithSearchOption),
    [beveragesSearchPool, category]
  );

  const amountInputRef = useRef();
  const priceInputRef = useRef();

  const [
    selectedOption,
    setSelectedOption,
    resetSelectedOption,
  ] = useStateWithReset(null);

  const [amountValue, setAmountValue, resetAmountValue] = useStateWithReset('');

  const [priceValue, setPriceValue, resetPriceValue] = useStateWithReset('');

  const fullReset = () => {
    resetSearching();

    resetSelectedOption();
    resetAmountValue();
    resetPriceValue();
  };

  const handleSelectFromSearch = (selected, { action }) => {
    if (!['select-option', 'create-option'].includes(action)) return;

    setSelectedOption(selected);
  };

  useEffect(() => {
    if (!selectedOption) return;

    const { data, value } = selectedOption;

    if (!value) return;

    if (!data) {
      focusOnRef(amountInputRef);
      return;
    }

    setAmountValue(data.amount);

    focusOnRef(priceInputRef);
  }, [selectedOption]);

  const handleAmountChange = event => {
    const { value } = event.target;

    setAmountValue(value);
  };

  const handlePriceChange = event => {
    const { value } = event.target;

    setPriceValue(value);
  };

  const handleSubmit = async event => {
    event.preventDefault();

    const { value, data, __isNew__: isNew } = selectedOption;

    const temp = isNew ? { name: value, position: null, editable: true } : data;

    const amount = amountValue === "" ? null : amountValue
    const price = priceValue === "" ? null : priceValue;

    const drink = new Drink({
      ...temp,
      amount,
      price,
      variants: [{amount, price}],
      required: false,
      id: uuid(),
    });

    try {
      loader.startLoading();

      await onSubmit(drink);
      fullReset();

      setSearching(true);

      loader.stopLoading();
    } catch (e) {
      window.alert(t('itemUpdateError'));
      console.error(e);
      loader.setError(e);
    }
  };

  const submittable =
    searching &&
    selectedOption &&
    selectedOption.value &&
    !loader.loading &&
    validate(BEVERAGE_TYPES.DRINK)({
      name: selectedOption.label,
      amount: amountValue,
      price: priceValue,
    });

  if (searching) {
    return (
      <form className="form-row">
        <div className="col-12 col-lg-4 mb-2 mb-lg-0">
          <CreateableSelect
            autoFocus
            openMenuOnFocus

            value={selectedOption}
            placeholder={t('name')}
            onChange={handleSelectFromSearch}
            options={searchOptions}
            styles={createableSelectStyles}
            innerProps={{
              // necessary to prevent form submission when selecting with Enter
              onKeyPress: event => event.preventDefault(),
            }}
            noOptionsMessage={() => t('nothingFound')}
            formatCreateLabel={generateCreationLabel(t('createDrink'))}
          />
        </div>

        <div className="col-3 col-lg-2">
          <input
            type="text"
            placeholder={t('amount')}
            className="form-control mr-2"
            value={amountValue}
            onChange={handleAmountChange}
            ref={amountInputRef}
          />
        </div>

        <div className="col-3 col-lg-2">
          <input
            type="number"
            className="form-control"
            placeholder={t('price')}
            value={priceValue}
            onChange={handlePriceChange}
            ref={priceInputRef}
          />
        </div>

        <div className="col-6 col-lg-4">
          <div className="btn-group form-control p-0 border-0">
            <button
              className="btn btn-primary text-nowrap w-100"
              disabled={!submittable}
              onClick={handleSubmit}
            >
              <i className="fa fa-plus fa-fw mr-1" />
              {t('add')}
            </button>
            <button
              className="btn btn-warning text-nowrap w-100"
              onClick={fullReset}
            >
              <i className="fa fa-times fa-fw mr-1" />
              {t('cancel')}
            </button>
          </div>
        </div>
      </form>
    );
  }

  return (
    <button className="btn btn-primary" onClick={() => setSearching(true)}>
      <span className="fa fa-fw fa-plus mr-2" />
      {t('addDrink')}
    </button>
  );
}
