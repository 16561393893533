import consumer from "./consumer"
import saveAs from "file-saver"

consumer.subscriptions.create("AsyncDownloadChannel",
    {
        // Called once when the subscription is created.
        connected() {
            console.log("AsyncDownload connected");
            this.install()
        },

        // Called when the WebSocket connection is closed.
        disconnected() {
            console.log("AsyncDownload connection closed");
            this.uninstall()
        },

        // Called when the subscription is rejected by the server.
        rejected() {
            console.log("AsyncDownload connection rejected");
            this.uninstall()
        },

        received(data) {
            this.downloadFile(data["url"]);
        },

        downloadFile(url) {
            let el = document.body;
            let startEvent = new CustomEvent('async-download:start', {detail: {url: url}});
            el.dispatchEvent(startEvent);
            saveAs(url);
            let endEvent = new CustomEvent('async-download:end', {detail: {url: url}});
            el.dispatchEvent(endEvent);
        },

        install() {
            // let initEvent = new Event('async-download:init');
            // this.el.dispatchEvent(initEvent);
        },

        uninstall() {
        },
    })
