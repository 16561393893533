import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import uuid from '../utils/uuid';

export const DrinkVariantInput = ({
  amountValue,
  amountOnChange,
  priceValue,
  priceOnChange,
  priceInputRef = null,
  onDelete,
  onCreate,
  last,
  only,
}) => {
  const [amountInputId, priceInputId] = useMemo(() => [uuid(), uuid()], []);
  const { t } = useTranslation();

  return (
    <div className="form-row">
      <div className="col-4">
        <label htmlFor={amountInputId}>{t('amount')}</label>
        <input
          type="text"
          className="form-control"
          id={amountInputId}
          value={amountValue}
          onChange={amountOnChange}
        />
      </div>

      <div className="col-4">
        <label htmlFor={priceInputId}>{t('price')}</label>
        <div className="d-flex">
          <input
            min={0}
            max={1000000}
            type="number"
            className="form-control"
            ref={priceInputRef}
            id={priceInputId}
            onChange={priceOnChange}
            value={priceValue}
          />
        </div>
      </div>

      <div className="col-4">
        <label>&nbsp;</label>
        <div className="form-control p-0 border-0 d-flex">
          {!only && (
            <button
              title={t('delete')}
              className="btn btn-danger btn-sm mr-1"
              type="button"
              onClick={onDelete}
            >
              <i className="fa fa-trash" />
            </button>
          )}

          {last && (
            <button
              title={t('addVariant')}
              className="btn btn-success btn-sm"
              type="button"
              onClick={onCreate}
            >
              <i className="fa fa-plus" />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
