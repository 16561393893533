import React from 'react';
import BannerSelector from '../components/BannerSelector';
import useMenuContext from '../hooks/useMenuContext';
import { EMPTY_BLOCK } from '../lib/constants';

export default function EmptyBlockScreen() {
  const {emptyBlockBanners} = useMenuContext();

  return (
    <div className="container">
      <BannerSelector type={EMPTY_BLOCK} banners={emptyBlockBanners} />
    </div>
  )
}
