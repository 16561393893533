import { useState, useMemo } from 'react';

export default function useLoader() {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');

  return {
    error,
    success,
    loading,
    errorMessage,
    setErrorMessage,
    resetError() {
      setError(null);
    },
    setError(err) {
      setError(err);
      setLoading(false);
    },
    startLoading() {
      setError(null);
      setLoading(true);
      setSuccess(false);
    },
    stopLoading() {
      setLoading(false);
      setSuccess(true);
    },
  };
}
