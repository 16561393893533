import InfoTourBubble from './components/InfoTourBubble';
import { Navbar } from './Navbar';
import React, { useEffect } from 'react';
import { ScreenRouter } from './ScreenRouter';
import toCamelCase from './utils/toCamelCase';
import { useConfigContext } from './hooks/useConfigContext';
import useDataContext from './hooks/useDataContext';
import { useInfoTourContext } from './contexts/InfoTourContext';
import useNavigationContext from './hooks/useNavigationContext';
import { useTranslation } from 'react-i18next';
import { APPEARANCE } from './lib/constants';
import monetize from './utils/monetize';

export const MenuBuilder = () => {
  const { activeBubbleName, runningInfoTour } = useInfoTourContext();
  const { setCurrentRoute } = useNavigationContext();
  const { t } = useTranslation();
  const {
    selectedTitlePage,
    setSelectedTitlePage,
  } = useConfigContext();
  const { currencyConfig, titlePages, locale } = useDataContext();

  useEffect(() => {
    if (selectedTitlePage.deleted) {
      setCurrentRoute(APPEARANCE);
      alert(t('AppearanceScreen.selectedTitlePageDeleted'));
      setSelectedTitlePage(titlePages.filter(tp => !tp.deleted)[0]);
    }
  }, [selectedTitlePage, setCurrentRoute, t]);

  useEffect(() => {
    monetize.init({
      ...currencyConfig,
      locale
    });
  }, [currencyConfig]);

  return (
    <>
      <Navbar />

      <main className={`preview d-flex flex-1 pt-3 ${locale}`}>
        <ScreenRouter />
        <InfoTourBubble
          bubbleName={activeBubbleName}
          text={t(
            `explanationTour.menuBuilder.${toCamelCase(
              runningInfoTour
            )}.${toCamelCase(activeBubbleName)}`
          )}
          className={'defaultBubblePage'}
        />
      </main>
    </>
  );
};
