import './Navbar.scss';

import {
  APPEARANCE,
  BEVERAGE_TYPES,
  EMPTY_BLOCK,
  PREVIEW,
} from './lib/constants';
import {
  MIN_QUANTITY,
  OrderPriceCalculator,
} from './components/OrderPriceCalculator';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import useAPI from './hooks/useAPI';
import { useBlocksContext } from './hooks/useBlocksContext';
import { useConfigContext } from './hooks/useConfigContext';
import { useInfoTourContext } from './contexts/InfoTourContext';
import useNavigationContext from './hooks/useNavigationContext';
import { useTranslation } from 'react-i18next';

const { COCKTAIL, PHOTO_COCKTAIL, DRINK } = BEVERAGE_TYPES;

export const Navbar = () => {
  const timeoutRef = useRef(0);

  const { drinkMenu, selectedTitlePage, selectedBlockFormat } = useConfigContext();

  const { hasEmptyBlock } = useBlocksContext();

  const {
    startInfoTour,
    activeBubbleName,
    runningInfoTour,
  } = useInfoTourContext();

  // for info tour
  const isIntro = runningInfoTour === 'INTRO';
  const isIntroBubbleSteps = activeBubbleName === 'STEPS' && isIntro;
  const isIntroBubbleViewSwitch = activeBubbleName === 'VIEW_SWITCH' && isIntro;
  const isIntroBubbleOrderPrint = activeBubbleName === 'ORDER_PRINT' && isIntro;

  const { orderQuantity: initOrderQuantity } = drinkMenu;

  const { t } = useTranslation();

  const API = useAPI();

  const [settingPrice, setSettingPrice] = useState(false);

  const [orderQuantity, setOrderQuantity] = useState(
    initOrderQuantity || MIN_QUANTITY
  );

  useEffect(() => {
    clearTimeout(timeoutRef.current);

    const saveOrderQuantity = async () => {
      try {
        await API.updateDrinkMenuAttributes({ orderQuantity });
      } catch (error) {
        console.log('error: ', error);
      }
    };

    timeoutRef.current = window.setTimeout(saveOrderQuantity, 100);

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [orderQuantity]);

  const { currentRoute, setCurrentRoute } = useNavigationContext();

  const tabRoutes = useMemo(() => {
    let routes;
    
    if (selectedBlockFormat === 'A5_summer' || selectedBlockFormat === 'A5_winter') {
      routes = [APPEARANCE, PHOTO_COCKTAIL];
    } else {
      routes = [APPEARANCE, DRINK, PHOTO_COCKTAIL, COCKTAIL];
      if (hasEmptyBlock) routes.push(EMPTY_BLOCK);
    }

    return routes;
  }, [hasEmptyBlock]);

  const handleLinkClick = (event, route) => {
    event.preventDefault();

    setCurrentRoute(route);
  };

  const handlePriceClick = event => {
    event.preventDefault();

    setSettingPrice(state => !state);
  };

  const _previewBtn = () => (
    <li
      className={`nav-item ${
        isIntroBubbleViewSwitch ? 'bg-white z-index-10 rounded' : ''
      }`}
    >
      <a
        className={`nav-link ${currentRoute === PREVIEW ? 'active' : ''}`}
        href="#"
        onClick={event => handleLinkClick(event, PREVIEW)}
      >
        {t('preview')}
      </a>
    </li>
  );

  const _priceBtn = () => (
    <li
      className={`nav-item ml-1 ${
        isIntroBubbleViewSwitch ? 'bg-white z-index-10 rounded' : ''
      }`}
    >
      <a
        href="#"
        onClick={handlePriceClick}
        className={`nav-link ${settingPrice ? 'active' : ''}`}
      >
        {t('price')}
      </a>
    </li>
  );

  const _orderBtn = () => (
    <li
      className={`nav-item ml-1  ${
        isIntroBubbleOrderPrint ? 'bg-white z-index-10 rounded' : ''
      }`}
    >
      <a
        className="nav-link"
        href={`/orders/new?order[order_item_attributes][drink_menu_id]=${drinkMenu.id}&order[order_item_attributes][quantity]=${orderQuantity}`}
      >
        {t('order')}
      </a>
    </li>
  );

  const isView = runningInfoTour === 'VIEW';
  const isDrinks = runningInfoTour === 'DRINKS';
  const isDrinksWithFoto = runningInfoTour === 'DRINKS_WITH_FOTO';
  const isOtherDrinks = runningInfoTour === 'OTHER_DRINKS';
  const isEmptyPage = runningInfoTour === 'EMPTY_PAGE';

  const resolveHighlight = route => {
    switch (route) {
      case 'appearance':
        return isView ? 'bg-white z-index-10 rounded' : '';
      case 'drink':
        return isDrinks ? 'bg-white z-index-10 rounded' : '';
      case 'photo_cocktail':
        return isDrinksWithFoto ? 'bg-white z-index-10 rounded' : '';
      case 'cocktail':
        return isOtherDrinks ? 'bg-white z-index-10 rounded' : '';
      case 'empty_block':
        return isEmptyPage ? 'bg-white z-index-10 rounded' : '';
      default:
        return '';
    }
  };

  const _navTabs = () => (
    <ul
      className={`nav nav-tabs ${
        isIntroBubbleSteps ? 'bg-white z-index-10 rounded-top' : ''
      }`}
    >
      {tabRoutes.map((route, index) => {
        const active = route === currentRoute;

        const routeName = t(
          Object.values(BEVERAGE_TYPES).includes(route)
            ? `${route}_plural`
            : route
        );
        const linkText = `${index + 1}. ${routeName}`;
        return (
          <li className={`nav-item ${resolveHighlight(route)}`} key={route}>
            <a
              className={`nav-link ${active ? 'active' : ''}`}
              href="#"
              onClick={event => handleLinkClick(event, route)}
            >
              {linkText}
            </a>
          </li>
        );
      })}
    </ul>
  );

  const _showInfoTour = () => {
    startInfoTour('INTRO');
  };

  return (
    <div className="row justify-content-center">
      <OrderPriceCalculator
        settingPrice={settingPrice}
        value={orderQuantity}
        onChange={setOrderQuantity}
      />

      <div className="col-12 d-flex justify-content-between">
        {_navTabs()}
        <div onClick={_showInfoTour} className="info-btn">
          <i className="far fa-question-circle"></i>
        </div>
        {/* <div className="info-tour-trigger" onClick={_showInfoTour}>
          &#8505;
        </div> */}

        <ul className="nav nav-pills">
          {_previewBtn()}
          {_priceBtn()}
          {_orderBtn()}
        </ul>
      </div>
    </div>
  );
};
