import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useEditingContext } from '../hooks/useEditingContext';
import useLoader from '../hooks/useLoader';
import Category from '../models/Category';

export const CategoryHeader = ({
  category,

  deleteable,
  editable,

  onCategoryDelete,
  onCategoryUpdate,

  dragHandleProps,

  // hide drag handle
  canBeDragged
}) => {
  const tempNameInit = category.name;
  const [tempName, setTempName] = useState(tempNameInit);

  const loader = useLoader();

  const { t } = useTranslation();

  const {
    editedEntity,
    setEditedEntity,
    resetEditedEntity,
  } = useEditingContext();

  const isEdited = editedEntity === category;

  const handleSubmitNameChange = async event => {
    event.preventDefault();

    const newCategory = new Category({ ...category, name: tempName });

    try {
      loader.startLoading();
      await onCategoryUpdate(newCategory);
      resetEditedEntity();
      loader.stopLoading();
    } catch (e) {
      loader.setError(e);
      console.error(e);
      window.alert(t('itemUpdateError'));
    }
  };

  const handleCancelNameChange = event => {
    event.preventDefault();

    resetEditedEntity();
    setTempName(tempNameInit);
  };

  const handleDeleteCategory = async event => {
    event.preventDefault();

    try {
      loader.startLoading();
      await onCategoryDelete(category);
      loader.stopLoading();
    } catch (e) {
      loader.setError(e);
      console.error(e);
      window.alert(t('itemUpdateError'));
    }
  };

  const submittable = Boolean(tempName) && !loader.loading;

  return (
    <div
      className={`card-header bg-dark text-white lead`}
    >
      {isEdited ? (
        <form className="form-row">
          <div className="col-10">
            <input
              type="text"
              style={{ fontSize: '1.25rem'}} // enforce lead-like font-size
              className="form-control"
              value={tempName}
              onChange={e => setTempName(e.target.value)}
            />
          </div>

          <div className="col-2">
            <div className="btn-group h-100 w-100">
              <button
                className="btn btn-sm btn-primary"
                onClick={handleSubmitNameChange}
                disabled={!submittable}
              >
                <i className="fa fa-check" />
              </button>
              <button
                className="btn btn-sm btn-warning"
                onClick={handleCancelNameChange}
              >
                <i className="fa fa-times" />
              </button>
              {deleteable && (
                <button
                  className="btn btn-sm btn-danger"
                  onClick={handleDeleteCategory}
                  disabled={!submittable}
                >
                  <i className="fa fa-trash" />
                </button>
              )}
            </div>
          </div>
        </form>
      ) : (
        <div className="d-flex justify-content-between">
          <span className={editable ? "hover-underline pointer" : ''}
                onClick={() => editable && (isEdited || setEditedEntity(category))}
          >{category.name}{editable && (
            <i className="fa fa-pen ml-4"/>
          )}</span>
          {canBeDragged && (
            <div {...dragHandleProps}>
              <i className="fa fa-arrows-alt fa-fw" />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
