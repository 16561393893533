import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useEditingContext } from '../hooks/useEditingContext';
import useLoader from '../hooks/useLoader';
import useMenuContext from '../hooks/useMenuContext';
import { BEVERAGE_TYPES } from '../lib/constants';
import { focusOnRef } from '../lib/focusOnRef';
import monetize from '../utils/monetize';
import { validate } from '../lib/validate';
import Drink from '../models/Drink';
import { DrinkVariantInput } from './DrinkVariantInput';

export default function DrinkComponent({
  item,
  onUpdate,
  onDelete,
  dragHandleProps,
  category,
}) {
  const loader = useLoader();

  const { t } = useTranslation();

  const { addBeverage } = useMenuContext();

  const { id, movable, required, editable, variants } = item;

  const priceInputRef = useRef();

  const {
    editedEntity,
    setEditedEntity,
    resetEditedEntity,
  } = useEditingContext();

  const isEdited = editedEntity === item;

  const [temp, setTemp] = useState({ ...item });

  const getFieldId = field => `${field}-${id}`;

  const handleUpdate = async event => {
    event.preventDefault();

    try {
      loader.startLoading();

      await onUpdate(new Drink(temp));

      loader.stopLoading();
    } catch (e) {
      loader.setError(e);
    }

    resetEditedEntity();
  };

  const handleDelete = async event => {
    event.preventDefault();

    try {
      loader.startLoading();

      await onDelete(item);
    } catch (e) {
      console.error(e);
      window.alert(t('itemDeletionError'));
      loader.setError(e);
    }
  };

  const handleCancel = event => {
    event.preventDefault();

    setTemp({ ...item });
    resetEditedEntity();
  };

  const updateTempField = (field, value) =>
    setTemp(old => ({ ...old, [field]: value }));

  const updateVariantField = (variantIndex, field, value) => {
    setTemp(old => {
      old.variants[variantIndex] = {
        ...old.variants[variantIndex],
        [field]: value,
      };
      return { ...old, variants: [...old.variants] };
    });
  };

  const handleCreateVariant = () => {
    setTemp(old => {
      return { ...old, variants: [...old.variants, { amount: '', price: '' }] };
    });
  };

  const handleDeleteVariant = index => {
    setTemp(old => {
      old.variants.splice(index, 1);
      return { ...old, variants: [...old.variants] };
    });
  };

  const nameEditable = editable && !required;

  const submittable =
    isEdited &&
    Boolean(temp.name) &&
    !loader.loading &&
    validate(BEVERAGE_TYPES.DRINK)(temp);

  useEffect(() => {
    if (isEdited) focusOnRef(priceInputRef);
  }, [isEdited]);

  if (isEdited) {
    return (
      <form className="form-row" style={{ maxWidth: 'initial' }}>
        <div className="col-lg-6 col-12 mb-2 mb-lg-0 form-group">
          <label htmlFor={getFieldId('name')}>
            {t('name')}
            {!nameEditable && <>&nbsp;({t('cannotChange')})</>}
          </label>
          <textarea
            className="form-control"
            id={getFieldId('name')}
            rows={1}
            value={temp.name}
            onChange={e => updateTempField('name', e.target.value)}
            readOnly={!nameEditable}
          />
        </div>

        <div className="col-lg-4 col-8 form-group">
          {temp.variants.map((variant, index, { length }) => {
            const { amount, price } = variant;

            const only = length === 1;
            const last = length === index + 1;

            return (
              <DrinkVariantInput
                key={index}
                amountValue={amount}
                amountOnChange={e =>
                  updateVariantField(index, 'amount', e.target.value)
                }
                priceValue={price}
                priceOnChange={e =>
                  updateVariantField(index, 'price', e.target.value)
                }
                priceInputRef={last ? priceInputRef : null}
                last={last}
                only={only}
                onCreate={handleCreateVariant}
                onDelete={event => handleDeleteVariant(index, event)}
              />
            );
          })}
        </div>

        <div className="col-lg-2 col-4 form-group">
          <label>&nbsp;</label>
          <div className="btn-group form-control p-0 border-0">
            <button
              title={t('confirm')}
              className="btn btn-sm btn-primary"
              onClick={handleUpdate}
              disabled={!submittable}
            >
              <i className="fa fa-check fa-fw" />
            </button>

            <button
              title={t('close')}
              className="btn btn-sm btn-warning"
              onClick={handleCancel}
            >
              <i className="fa fa-times fa-fw" />
            </button>

            {!required && (
              <button
                className="btn btn-sm btn-danger"
                onClick={handleDelete}
                title={t('delete')}
              >
                <i className="fa fa-trash fa-fw" />
              </button>
            )}
          </div>
        </div>
      </form>
    );
  }

  const startEditing = () => setEditedEntity(item);

  const handleDuplicate = async () => {
    try {
      const copiedDrink = new Drink({
        ...item,
        originId: null,
        originPosition: null,
        position: item.position + 1,
      });
      await addBeverage(copiedDrink, category);
    } catch (err) {
      alert(t('itemCreationError'));
      console.error(err);
    }
  };

  return (
    <div className="row hover-underline pointer border-bottom-0">
      <div
        className="col-12 col-lg-6 d-flex align-items-center"
        onClick={startEditing}
      >
        {item.name}
      </div>

      <div
        className="col-10 col-lg-4 align-items-center"
        onClick={startEditing}
      >
        {variants.map((variant, index) => {
          const { amount, price } = variant;

          const formattedPrice = monetize.format(price);

          return (
            <div className="form-row" key={index}>
              <div className="col">{amount || null}</div>
              <div className="col">
                {formattedPrice || (
                  <button
                    className="btn btn-secondary btn-sm"
                    onClick={startEditing}
                  >
                    {t('enterPrice')}
                  </button>
                )}
              </div>
            </div>
          );
        })}
      </div>

      {movable && (
        <div
          className="col-2 col-lg-2 lead d-flex align-items-center justify-content-end"
          {...dragHandleProps}
        >
          <i className="fa fa-arrows-alt fa-fw" />
        </div>
      )}
    </div>
  );
}
