import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useAPI from '../hooks/useAPI';
import { useConfigContext } from '../hooks/useConfigContext';
import useLoader from '../hooks/useLoader';
import useMenuContext from '../hooks/useMenuContext';

export const PrefillButton = () => {
  const API = useAPI();

  const { selectedTitlePage } = useConfigContext();

  const { menuItems } = useMenuContext();

  const loader = useLoader();

  const { t } = useTranslation();

  const handlePrefillClick = async () => {
    try {
      loader.startLoading();

      // request prefill on the drink menu
      await API.requestPrefilledDrinkMenu(selectedTitlePage);

      // then reload the page
      Turbolinks.visit(`${window.location}?prefill`, {action: 'replace'});
    } catch (err) {
      loader.setError(err);
      console.error(err);
    }
  }

  const canPrefill = useMemo(
    () => !Object.values(menuItems).filter(item => !item.required).length,
    [menuItems]
  );

  if (!canPrefill) return null;

  return (
    <div>
      <button className="btn btn-primary" onClick={handlePrefillClick} disabled={loader.loading}>
        {t('prefillBeverages')}
      </button>
      <span className="ml-2">{t('prefillMessage')}</span>
    </div>

  );
};
