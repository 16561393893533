import {
  APPEARANCE,
  BEVERAGE_TYPES,
  EMPTY_BLOCK,
  PREVIEW,
} from './lib/constants';

import AppearanceScreen from './screens/AppearanceScreen';
import CategoriesScreen from './screens/CategoriesScreen';
import EmptyBlockScreen from './screens/EmptyBlockScreen';
import PhotoCocktailScreen from './screens/PhotoCocktailScreen';
import PreviewScreen from './screens/PreviewScreen';
import React from 'react';
import useMenuContext from './hooks/useMenuContext';
import useNavigationContext from './hooks/useNavigationContext';

const { COCKTAIL, PHOTO_COCKTAIL, DRINK } = BEVERAGE_TYPES;

export const ScreenRouter = () => {
  const { currentRoute } = useNavigationContext();

  const { cocktailCategories, drinkCategories } = useMenuContext();

  if (currentRoute === PHOTO_COCKTAIL) return <PhotoCocktailScreen />;

  if (currentRoute === COCKTAIL)
    return <CategoriesScreen type={COCKTAIL} categories={cocktailCategories} />;

  if (currentRoute === DRINK)
    return <CategoriesScreen type={DRINK} categories={drinkCategories} />;

  if (currentRoute === APPEARANCE) return <AppearanceScreen />;

  if (currentRoute === PREVIEW) return <PreviewScreen />;

  if (currentRoute === EMPTY_BLOCK) return <EmptyBlockScreen />;

  return null;
};
