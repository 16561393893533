import './InfoTourContext.scss';

import * as React from 'react';

import { useContext } from 'react';

const { useMemo, useState } = React;

const INTRO = ['STEPS', 'VIEW_SWITCH', 'ORDER_PRINT'];
const VIEW = ['FORMAT', 'PAGE_NUM', 'LOGO'];
const DRINKS = ['ORDER', 'FILTER', 'CREATE', 'DRINK_CONTENT', 'CHANGES'];
const DRINKS_WITH_FOTO = ['DEFAULT_LIST', 'ARE_ON_LAST_PAGE'];
const OTHER_DRINKS = ['COCTAILS_AND_MIX', 'ORDER', 'CONTENT'];
const EMPTY_PAGE = ['WHEN_IS_SHOWED', 'FULLFIL', 'IF_SPACE_BIG', 'RULES'];

const tours = {
  INTRO,
  VIEW,
  DRINKS,
  DRINKS_WITH_FOTO,
  OTHER_DRINKS,
  EMPTY_PAGE,
};

const toursArr = [
  'INTRO',
  'VIEW',
  'DRINKS',
  'DRINKS_WITH_FOTO',
  'OTHER_DRINKS',
  'EMPTY_PAGE',
];

export type InfoTourProps = {
  startInfoTour: (name: string) => void;
  prevTourBubble: () => void;
  runningInfoTour: string;
  activeBubbleName: string;
  nextTourBubble: () => void;
  closeInfoTour: () => void;
};

const InfoTourContext = React.createContext({} as InfoTourProps);

export default InfoTourContext;

export const useInfoTourContext = () => {
  return useContext(InfoTourContext);
};

type Props = {
  children: React.ReactNode;
};

const InfoTourProvider = ({ children }: Props) => {
  const [isOverlay, setIsOverlay] = useState(false);
  const [activeBubbleName, setActiveBubbleName] = useState('');
  const [runningInfoTour, setRunningInfoTour] = useState('');

  React.useEffect(() => {
    const params = window.location.search;
    const showTour = params.includes('first=true');
    if (showTour) {
      startInfoTour('INTRO');
    }
  }, []);

  const startInfoTour = React.useCallback((name: string) => {
    setIsOverlay(true);
    setRunningInfoTour(name);
    setActiveBubbleName(tours[name][0]);
  }, []);

  const closeInfoTour = async () => {
    setIsOverlay(false);
    setActiveBubbleName('');
    setRunningInfoTour('');
  };

  const nextTourBubble = () => {
    const activeIndex = tours[runningInfoTour].findIndex(
      ind => ind === activeBubbleName
    );

    const activeTourIndx = toursArr.findIndex(ind => ind === runningInfoTour);
    const isLastTour = toursArr.length === activeTourIndx + 1;
    const isLastTourBubble = activeIndex === tours[runningInfoTour].length - 1;

    if (isLastTour && isLastTourBubble) {
      closeInfoTour();
      return;
    }

    if (isLastTourBubble) {
      const nextTour = toursArr[activeTourIndx + 1];
      setRunningInfoTour(nextTour);
      setActiveBubbleName(tours[nextTour][0]);
      return;
    }

    setActiveBubbleName(tours[runningInfoTour][activeIndex + 1]);
  };

  const prevTourBubble = () => {
    const activeTourIndx = toursArr.findIndex(ind => ind === runningInfoTour);
    const activeIndex = tours[runningInfoTour].findIndex(
      ind => ind === activeBubbleName
    );

    const isFirstTour = activeTourIndx === 0;
    const isFirstTourBubble = activeIndex === 0;

    if (isFirstTourBubble && isFirstTour) {
      closeInfoTour();
      return;
    }

    if (isFirstTourBubble) {
      const prevTour = toursArr[activeTourIndx - 1];
      setRunningInfoTour(prevTour);
      setActiveBubbleName(tours[prevTour][0]);
      return;
    }

    setActiveBubbleName(tours[runningInfoTour][activeIndex - 1]);
  };

  const value = useMemo(
    () => ({
      startInfoTour,
      activeBubbleName,
      runningInfoTour,
      nextTourBubble,
      prevTourBubble,
      closeInfoTour,
    }),
    [
      activeBubbleName,
      closeInfoTour,
      nextTourBubble,
      runningInfoTour,
      prevTourBubble,
      startInfoTour,
    ]
  );

  return (
    <InfoTourContext.Provider value={value}>
      {children}
      {isOverlay && <div className="overlay-info-tour" />}
    </InfoTourContext.Provider>
  );
};

export { InfoTourProvider };
