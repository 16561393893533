import healthNoticeLogoSrc from '../../../images/health-psa-logo-black.png';
import { UNIT_TO_PIXELS } from '../lib/constants';
import DOMNodeWrapper from '../models/DOMNodeWrapper';
import { simulateBlockWithItems } from '../utils/paginatePhotoCocktailsAndCocktailCategories';
import i18n from '../i18n';

export const createContentContainer = () => {
  const contentContainer = window.document.createElement('div');
  contentContainer.classList.add('content-container');

  return contentContainer;
};

export const createBannersContainer = () => {
  const contentContainer = window.document.createElement('div');
  contentContainer.classList.add('banners-container');

  return contentContainer;
};

export const createHealthPSA = () => {
  const locale = i18n.language;
  const healthNotice = document.createElement('div');
  healthNotice.classList.add('health-psa');

  const text = document.createElement('div');
  text.classList.add('health-psa__text');
  text.innerHTML = i18n.t('HealthPsa.allergens_warning');
  healthNotice.appendChild(text);

  let logo;
  if (locale === "sk") {
    logo = document.createElement('div');
  } else {
    logo = document.createElement('img');
    logo.src = healthNoticeLogoSrc;
  }
  logo.classList.add('health-psa__logo');
  healthNotice.appendChild(logo);

  // const copyright = document.createElement('div');
  // copyright.classList.add('health-psa__copyright');
  // copyright.innerHTML = [
  //   "Jack Daniel's Jsou registrované ochranné známky.",
  //   "©2020 Jack Daniel's. vsechna práva vyhrazena."
  // ].join('\n');
  // healthNotice.appendChild(copyright);

  return healthNotice;
};

export function appendHealthPSAToBlock(block) {
  block.classList.add('block--with-health-psa');
  block.appendChild(createHealthPSA());
}

export const createBlockElement = () => {
  const blockElement = window.document.createElement('div');
  blockElement.classList.add('block');

  return blockElement;
};

export const createBlockWithItems = (items, options = {}) => {
  const blockElement = createBlockElement();
  const contentContainer = createContentContainer();
  blockElement.appendChild(contentContainer);

  const bannersContainer = createBannersContainer();
  blockElement.appendChild(bannersContainer);

  items.forEach(item => {
    const { domNode } = item;
    contentContainer.appendChild(domNode);
  });

  if (options.className) {
    options.className.split(' ').forEach(htmlClass => {
      blockElement.classList.add(htmlClass);
    });
  }

  if (options.style) {
    Object.entries(options.style).forEach(([key, value]) => {
      blockElement.style[key] = value;
    });
  }

  return blockElement;
};

export function createSimulator(blockFormat) {
  const paginationSimulator = document.createElement('div');
  paginationSimulator.style.cssText = `
    position: absolute;
    top: 0;
    left: 0;
 `;
  paginationSimulator.classList.add('pagination-simulator');
  paginationSimulator.classList.add(blockFormat);

  return paginationSimulator;
}

export const createDrinksBlock = items => {
  return createBlockWithItems(items, {
    className: `categories drink-categories`,
  });
};
export const createCocktailsBlock = items => {
  return createBlockWithItems(items, {
    className: 'categories cocktail-categories',
  });
};

export const createEmptyBlock = () => {
  return createBlockWithItems([], {
    className: 'block--empty',
  });
};

export const createColumn = items => {
  const column = document.createElement('div');
  column.classList.add('column');

  items.forEach(item => column.appendChild(item.domNode));

  const bannersContainer = createBannersContainer();
  column.appendChild(bannersContainer);

  return new DOMNodeWrapper({ domNode: column, origin: 'Column' });
};

export const createBlockFromTitlePage = ({
  titlePage: titlePageData,
  drinkMenu,
}) => {
  const { imageSrc, code } = titlePageData;

  const { name, address, logoPath } = drinkMenu;

  const info = document.createElement('div');
  info.classList.add('title-page__info');

  if (name) {
    const nameEl = document.createElement('div');
    nameEl.classList.add('name');
    nameEl.innerHTML = name;
    info.appendChild(nameEl);
  }

  if (address) {
    const addressEl = document.createElement('div');
    addressEl.classList.add('address');
    addressEl.innerHTML = address;
    info.appendChild(addressEl);
  }

  if (logoPath) {
    const logoEl = document.createElement('img');
    logoEl.classList.add('logo');
    logoEl.src = logoPath;
    info.appendChild(logoEl);
  }

  const infoItem = new DOMNodeWrapper({ domNode: info, origin: titlePageData });

  const block = createBlockWithItems([infoItem], {
    className: `title-page title-page--${code}`,
    style: {
      backgroundImage: `url(${imageSrc})`,
    },
  });

  // const image = document.createElement('img');
  // image.classList.add('title-page__image');
  // image.src = imageSrc;
  //
  // block.appendChild(image);

  return block;
};

export const simulateDrinkBlock = (items, simulator) => {
  const column = createColumn(items);

  return simulateBlockWithItems([column], simulator, createDrinksBlock);
};

export const simulateCocktailsBlock = (items, simulator) =>
  simulateBlockWithItems(items, simulator, createCocktailsBlock);

export const simulatePhotoCocktailsBlock = (items, simulator) =>
  simulateBlockWithItems(items, simulator);

export const convertPixelsToMillimeters = px => px / UNIT_TO_PIXELS.mm;
