import uuid from '../utils/uuid';

export default class Category {
  constructor({
    id = uuid(),
    name,
    items,
    position = null,
    type,
    beverageIds = [],

    originId = null,
    originPosition = null,
  }) {
    this.id = id;
    this.items = items;
    this.beverageIds = beverageIds;
    this.name = name;
    this.position = position;
    // this.type = type ? type.split('_')[0] : null;
    this.type = type;

    this.originId = originId;
    this.originPosition = originPosition;

  }

  get movable() {
    return !['Tennessee Whiskey', 'Whisk(e)y & Bourbon'].includes(this.name);
  }

  get domTitle() {
    // TODO: remove this
    this.domNode;
  }

  // category's DOM Node is merely its title
  // child item are assumed to be able to render themselves
  get domNode() {
    const categoryTitle = document.createElement('div');
    categoryTitle.classList.add('category-title');

    categoryTitle.innerHTML = this.name;

    return categoryTitle;
  }

  toRequest(drinkMenu) {
    // {"data": {"type": "drink_menu_category", "attributes": {"name": "Updated custom drink menu category", "position": 6}}}
    return {
      data: {
        type: `drink_menu_category`,
        attributes: {
          drink_menu_id: drinkMenu.id,
          name: this.name,
          position: this.position,
          category_id: this.originId,
          origin_position: this.originPosition,
        },
      },
    };
  }
}
