import uuid from '../utils/uuid';
import pluralize from 'pluralize';

export default class Banner {
  constructor({
                blockFormat,
                fullWidth,
                id,
                image,
                imagePath,
                imagePreviewPath,
                name,
                template,
                type,
                originId = null,
                section = null,

                // position = null,

                imageWidth,
                imageHeight,
                variant,
              }) {
    this.blockFormat = blockFormat;
    this.fullWidth = fullWidth;
    this.id = id;

    this.image = image;
    this.imagePath = imagePath;
    this.imagePreviewPath = imagePreviewPath;

    this.name = name;
    this.originId = originId;
    this.template = template;
    this.type = type;
    this.originId = originId;
    this.section = section;

    // this.position = position;
    this.position = null; // should be inserted in whatever order it was added

    this.imageWidth = imageWidth;
    this.imageHeight = imageHeight;

    this.id = id || uuid();
    this.variant = variant;
  }

  get beverageType() {
    if (!this.section) return null;

    return pluralize.singular(this.section);
  }

  toRequest(drinkMenu) {
    return {
      data: {
        type: 'banner_item',
        attributes: {
          drink_menu_id: drinkMenu.id,
          section: this.section,
          position: this.position,

          image_width: this.imageWidth,
          image_height: this.imageHeight,

          banner_id: this.originId,
        },
      },
    };
  }

  scaleToWidth(containerWidth) {
    const heightToWidthRatio = this.imageHeight / this.imageWidth;

    this.imageWidth = Math.round(containerWidth);
    this.imageHeight = Math.round(containerWidth * heightToWidthRatio);
  }

  get domNode() {
    const { imagePath } = this;

    const img = document.createElement('img');
    img.classList.add('banner-img');
    img.src = imagePath;
    img.alt = this.name;

    img.style.width = `${this.imageWidth}px`;
    img.style.height = `${this.imageHeight}px`;

    return img;
  }
}