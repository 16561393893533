import React, { useMemo } from 'react';
import { useConfigContext } from '../hooks/useConfigContext';
import { useLibraryContext } from '../hooks/useLibraryContext';
import useMenuContext from '../hooks/useMenuContext';
import { checkIfLibraryItemExistsAmongMenuItems } from '../lib/checkIfLibraryItemExistsAmongMenuItems';
import { filterBasedOnTitlePage } from '../lib/filterBasedOnTitlePage';

const SearchPoolContext = React.createContext({});

export default SearchPoolContext;

export const SearchPoolProvider = ({ children }) => {
  const {
    categoriesLibrary,
    beveragesLibrary,
    bannersLibrary,
  } = useLibraryContext();

  const { menuItems, menuCategories } = useMenuContext();

  const { selectedTitlePage } = useConfigContext();

  const categoriesSearchPool = useMemo(() => {
    const menuCategoriesArray = Object.values(menuCategories);

    return Object.fromEntries(
      Object.entries(categoriesLibrary).map(([type, categoriesHash]) => {
        const categoriesArr = Object.values(categoriesHash)
          .filter(
            category =>
              !checkIfLibraryItemExistsAmongMenuItems(
                category,
                menuCategoriesArray
              )
          )

        return [type, categoriesArr];
      })
    );
  }, [categoriesLibrary, menuCategories]);

  const beveragesSearchPool = useMemo(() => {
    const menuItemsArray = Object.values(menuItems);

    return Object.fromEntries(
      Object.entries(beveragesLibrary).map(([type, beveragesHash]) => {
        const libArr = Object.values(beveragesHash)
          .sort((a, b) => a?.position - b?.position)
          .filter(
            beverage =>
              !checkIfLibraryItemExistsAmongMenuItems(beverage, menuItemsArray)
          )

        return [type, libArr];
      })
    );
  }, [beveragesLibrary, menuItems]);

  const bannersSearchPool = useMemo(
    () =>
      Object.values(bannersLibrary).filter(
        filterBasedOnTitlePage(selectedTitlePage)
      ),
    [bannersLibrary, selectedTitlePage]
  );

  return (
    <SearchPoolContext.Provider
      value={{
        beveragesSearchPool,
        categoriesSearchPool,
        bannersSearchPool,
      }}
    >
      {children}
    </SearchPoolContext.Provider>
  );
};
