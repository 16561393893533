import React from 'react';
import { useStateWithReset } from '../hooks/useStateWithReset';

const EditingContext = React.createContext({});

export default EditingContext;

export const EditingProvider = ({ children }) => {
  const initEditedEntity = null;

  const [editedEntity, setEditedEntity, resetEditedEntity] = useStateWithReset(initEditedEntity);

  return (
    <EditingContext.Provider
      value={{
        editedEntity,
        setEditedEntity,
        resetEditedEntity,
      }}
    >
      {children}
    </EditingContext.Provider>
  );
};
