export const calculateInsertionIndex = (
  item,
  list,
  shouldUseOriginPosition
) => {
  // console.group('calculating insertion index')
  // console.log('item', item.name, 'position:', item.position ,'origin_position', item.originPosition);
  // console.log('list', list.map(({name, position, originPosition}) => `${name} - ${position} - ${originPosition}`));
  // console.groupEnd();

  const { position, originPosition } = item;
  const { length } = list;

  let insertionIndex;

  if (shouldUseOriginPosition && originPosition !== null) {
    insertionIndex = 0;
    let maxOriginPositionBelowItemOriginPosition = null;

    list.forEach(({ originPosition: itemOriginPosition }, itemIndex) => {
      if (itemOriginPosition === null) return;

      if (
        originPosition > itemOriginPosition &&
        maxOriginPositionBelowItemOriginPosition < itemOriginPosition
      ) {
        maxOriginPositionBelowItemOriginPosition = itemOriginPosition;
        insertionIndex = itemIndex + 1;
      }
    });

    // if the item we're trying to place has originPosition,
    // but none of its neighbors have originPosition,
    // place that item last
    if (!list.some(({ originPosition }) => originPosition !== null))
      insertionIndex = length;
  } else {
    const itemPosition = position === null ? length : position;

    // should always be (inclusively) between 0 and list.length
    insertionIndex = Math.min(Math.max(0, itemPosition), length);
  }

  let itemAtInsertionIndex = list[insertionIndex];

  // make sure unmovable items hold their positions
  while (itemAtInsertionIndex && !itemAtInsertionIndex.movable) {
    insertionIndex += 1;
    itemAtInsertionIndex = list[insertionIndex];
  }

  return insertionIndex;
};
