import React, { useState } from 'react';
import useDataContext from '../hooks/useDataContext';

const ConfigContext = React.createContext({});

export default ConfigContext;

export const ConfigProvider = ({ children }) => {
  const { titlePages, drinkMenu: initDrinkMenu, locale } = useDataContext();

  // template options
  const [selectedTitlePage, setSelectedTitlePage] = useState(() => {
    const foundTitlePage = titlePages.find(
      titlePage =>
        initDrinkMenu.titlePage && titlePage.id === initDrinkMenu.titlePage.id
    );

    if (!foundTitlePage) return titlePages[0];

    return foundTitlePage;
  });

  const [drinkMenu, setDrinkMenu] = useState({ ...initDrinkMenu});

  const [selectedBlockFormat, setSelectedBlockFormat] = useState(selectedTitlePage.blockFormat);

  const [selectedMaterial, setSelectedMaterial] = useState(initDrinkMenu.material?.id || 'standard');

  return (
    <ConfigContext.Provider
      value={{
        selectedBlockFormat,
        selectedTitlePage,
        setSelectedBlockFormat,
        setSelectedTitlePage,

        drinkMenu,
        setDrinkMenu,
        locale,
        selectedMaterial,
        setSelectedMaterial,
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};
