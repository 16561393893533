import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useEditingContext } from '../hooks/useEditingContext';
import useLoader from '../hooks/useLoader';
import { BEVERAGE_TYPES } from '../lib/constants';
import { focusOnRef } from '../lib/focusOnRef';
import { validate } from '../lib/validate';

export const PhotoCocktailPriceForm = ({ photoCocktail, onSubmit }) => {
  const { t } = useTranslation();

  const loader = useLoader();

  const {
    editedEntity,
    setEditedEntity,
    resetEditedEntity,
  } = useEditingContext();

  const { price: initPrice, formattedPrice } = photoCocktail;

  const [tempPrice, setTempPrice] = useState(initPrice || '');
  const resetTempPrice = () =>
    setTempPrice(initPrice === 0 ? 0 : initPrice || '');

  const priceInputRef = useRef();

  function handleEdit() {
    setEditedEntity(photoCocktail);
  }

  const handleCancelEdit = event => {
    event.preventDefault();

    resetEditedEntity();
    resetTempPrice();
  };

  const handleSubmit = async event => {
    event.preventDefault();

    try {
      loader.startLoading();

      await onSubmit(tempPrice);
      resetEditedEntity();

      loader.stopLoading();
    } catch (e) {
      console.error(e);
      loader.setError(e);
      window.alert(t('itemUpdateError'));
    }
  };

  function handleChangePrice(event) {
    const { value } = event.target;

    setTempPrice(value);
  }

  const isEdited = editedEntity === photoCocktail;

  useEffect(() => {
    if (isEdited) focusOnRef(priceInputRef);
  }, [isEdited]);

  const submittable = !loader.loading && validate(BEVERAGE_TYPES.PHOTO_COCKTAIL)({price: tempPrice});

  return (
    <div className="card-text d-flex justify-content-between align-items-end w-75 price">
      {isEdited ? (
        <form className="form-row" onSubmit={handleSubmit}>
          <div className="form-group col-9">
            <label htmlFor="">{t('price')}:</label>

            <input
              ref={priceInputRef}
              className="form-control"
              onChange={handleChangePrice}
              type="number"
              value={tempPrice}
              min={0}
              max={9999}
              required
            />
          </div>

          <div className="form-group d-flex align-items-end col-3">
            <div className="btn-group form-control border-0 p-0">
              <button
                className="btn btn-sm btn-primary"
                onClick={handleSubmit}
                disabled={!submittable}
              >
                <i className="fa fa-check fa-fw" />
              </button>

              <button
                className="btn btn-sm btn-warning"
                onClick={handleCancelEdit}
              >
                <i className="fa fa-times fa-fw" />
              </button>
            </div>
          </div>
        </form>
      ) : (
        <div>
          {formattedPrice ? (
            <div
              className="display-4 pointer hover-underline d-flex align-items-center price_value"
              onClick={handleEdit}
            >
              {formattedPrice}
              <span className="lead">
                <i className="fa fa-pen ml-4" />
              </span>
            </div>
          ) : (
            <div onClick={handleEdit} className="w-100">
              <div className="badge badge-warning pointer text-uppercase w-100 badge-text-large">
                {t('noPriceSet')}
              </div>
              <button
                className="btn btn-secondary w-100"
                onClick={handleEdit}
              >{t('enterPrice')}</button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
