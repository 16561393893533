import { BLOCK_FORMATS } from './constants';
import { camel } from 'kitsu-core';
import { capitalize } from 'lodash';
import { compareByPosition } from './compareByPosition';

export const deserializeDrinkMenu = drinkMenuData => {
  drinkMenuData = { ...drinkMenuData };
  drinkMenuData.menuCategories = drinkMenuData.menuCategories
    .sort(compareByPosition)
    .map(menuCategory => {
      const category = {
        ...deserializeObject(menuCategory),
        type: menuCategory.type.split('_')[0],
      };

      return category;
    });

  drinkMenuData.items = drinkMenuData.items.map(item =>
    deserializeObject(item)
  );

  return drinkMenuData;
};
export const deserializeObject = obj => {
  const relationships = Object.entries(obj.relationships || {}).reduce(
    (relRes, [relKey, relValue]) => {
      const { data } = relValue;

      if (!data) return relRes;

      return { ...relRes, [relKey]: data.id };
    },
    {}
  );

  delete obj.relationships;

  obj = { ...obj, ...relationships };

  obj = Object.entries(obj).reduce(
    (res, [objKey, objValue]) => ({
      ...res,
      [camel(objKey)]: objValue,
    }),
    {}
  );

  if (obj.blockFormat === '1/2A4') obj.blockFormat = 'halfA4';
  if (obj.blockFormat === 'A5-2') obj.blockFormat = 'A4';

  // TODO: refactor into models
  if (obj.type === 'title_page') {
    // TODO: remove this when seeds are fixed
    // if (!obj.template) {
    //   obj.template = obj.variant === 'modra' ? 'finlandia' : 'jack_daniels';
    // }

    const formattedTemplateName = obj.template
      .split('_')
      .map(capitalize)
      .join(' ');

    obj.formattedName = `${formattedTemplateName} ${capitalize(obj.variant)} ${
      BLOCK_FORMATS[obj.blockFormat]
    }`;

    obj.code = `${obj.template}-${obj.variant}`;
  }

  if (obj.type === 'drink_menu') return deserializeDrinkMenu(obj);

  if (obj.id) obj.originId = obj.id;

  return obj;
};
