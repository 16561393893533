import React from 'react';
import { AppearanceCard } from '../screens/AppearanceCard';
import { useTranslation } from 'react-i18next';

const MaterialSelector = ({ selectedMaterial, onMaterialChange }) => {
  const { t } = useTranslation();

  return (
    <div className="row mb-4">
      <div className="col-12 mb-2">
        <h4>{t('AppearanceScreen.selectMaterial')}</h4>
      </div>
      <div className="col-lg-6 col-12 mb-4">
        <AppearanceCard
          selected={selectedMaterial === 'standard'}
          onClick={() => onMaterialChange('standard')}
          label={t('AppearanceScreen.standardMaterial')}
        />
      </div>
      <div className="col-lg-6 col-12 mb-4">
        <AppearanceCard
          selected={selectedMaterial === 'laminate'}
          onClick={() => onMaterialChange('laminate')}
          label={t('AppearanceScreen.laminateMaterial')}
        />
      </div>
    </div>
  );
};

export default MaterialSelector;
