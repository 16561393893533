import * as A5Img from '../../../images/block-format-A5.png';
import * as halfA4Img from '../../../images/block-format-halfA4.png';
import * as summerMenu from '../../../images/block-format-summer_menu.png';
import * as winterMenu from '../../../images/block-format-winter_menu.png';

export const BEVERAGE_TYPES = {
  PHOTO_COCKTAIL: 'photo_cocktail',
  COCKTAIL: 'cocktail',
  DRINK: 'drink',
};

export enum BEVERAGE_TYPES_ENUM {
  PHOTO_COCKTAIL = 'photo_cocktail',
  COCKTAIL = 'cocktail',
  DRINK = 'drink',
}

export const BANNER = 'banner';

export const BLOCK_FORMATS = {
  halfA4: '1/2 A4',
  A5: 'A5',
  A4: 'A4',
  A5_summer: 'A5 Summer Menu',
  A5_winter: 'A5 Winter Menu',
};
export const APPEARANCE = 'appearance';
export const PREVIEW = 'preview';
export const EMPTY_BLOCK = 'empty_block';

export const UNIT_TO_PIXELS = {
  px: 1,
  in: 96,
  cm: 37.8,
  mm: 3.7795275591,
};

export const TEMPLATES = {
  DIPLOMATICO: 'diplomatico',
  FINLANDIA: 'finlandia',
  JACK_DANIELS: 'jack_daniels',
};
export const BLOCK_FORMAT_IMAGES = {
  halfA4: halfA4Img,
  A5: A5Img,
  A4: halfA4Img,
  A5_summer: summerMenu,
  A5_winter: winterMenu,
};
