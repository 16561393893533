import './i18n';

import * as Sentry from '@sentry/react';

import React, { useEffect } from 'react';

import { BlocksProvider } from './contexts/BlocksContext';
import { ConfigProvider } from './contexts/ConfigContext';
import { DataProvider } from './contexts/DataContext';
import { EditingProvider } from './contexts/EditingContext';
import { InfoTourProvider } from './contexts/InfoTourContext';
import { LibraryProvider } from './contexts/LibraryContext';
import { MenuBuilder } from './MenuBuilder';
import { MenuProvider } from './contexts/MenuContext';
import { NavigationProvider } from './contexts/NavigationContext';
import { SearchPoolProvider } from './contexts/SearchPoolContext';
import i18next from 'i18next';

Sentry.init({
  dsn:
    'https://2d7c203fbc0a4caa8dc036e39b58bc6c@o53741.ingest.sentry.io/2600070',
});

export default function App(props) {
  useEffect(() => {
    (async () => {
      await i18next.changeLanguage(props.locale);
      // force page reloading if arrived via back/forward history navigation
      // to avoid weird application states
      try {
        const historyInput = document.getElementById(
          'fromHistory'
        ) as HTMLInputElement;
        if (!historyInput) throw Error('No history input element found');

        if (historyInput.value) {
          // arrived here via history, as the input value persisted
          historyInput.value = '';
          window.location.reload();
        } else {
          // arrived here normally
          historyInput.value = 'fromHistory';
        }
      } catch (e) {
        console.error(`Error getting history data : ${e}`);
      }
    })();
  }, []);

  return (
    <NavigationProvider>
      <InfoTourProvider>
        <DataProvider masterAppProps={props}>
          <LibraryProvider>
            <ConfigProvider>
              <MenuProvider>
                <SearchPoolProvider>
                  <BlocksProvider>
                    <EditingProvider>
                      <MenuBuilder />
                    </EditingProvider>
                  </BlocksProvider>
                </SearchPoolProvider>
              </MenuProvider>
            </ConfigProvider>
          </LibraryProvider>
        </DataProvider>
      </InfoTourProvider>
    </NavigationProvider>
  );
}
