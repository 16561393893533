import { BEVERAGE_TYPES } from '../lib/constants';
import Beverage from './Beverage';

export default class PhotoCocktail extends Beverage {
  constructor(data) {
    super(data);
    this.type = BEVERAGE_TYPES.PHOTO_COCKTAIL;
  }

  get formattedIngredients() {
    return this.ingredients.filter(Boolean).map(i => {
      // Use a regex to match the pattern [number][space][unit][word]
      return i.trim().replace(/(\d+)\s+(\w+)\s+(\w+)/g, (match, p1, p2, p3) => {
        return `${p1}&nbsp;${p2}&nbsp;${p3}`;
      });
    }).map(i => `<span>${i}</span>`).join('\n');
  }

  get domNode() {
    // <div class="photo-cocktail">
    //   <img
    //     class="photo-cocktail__photo"
    //     src="${examplePhotoCocktail.imageSrc}"
    //   />
    //
    //   <div class="photo-cocktail__line"></div>
    //
    //   <div class="photo-cocktail-description">
    //     <h3 class="photo-cocktail-description__heading">
    //       JACK
    //       <br />& COLA
    //     </h3>
    //
    //     <div class="photo-cocktail-description__ingredients">
    //       4 cl Jack Daniel’s
    //       <br />
    //       soda, led
    //     </div>
    //   </div>
    //
    //   <div class="photo-cocktail-price">
    //     <div class="photo-cocktail-price__number">105,-</div>
    //   </div
    // </div>

    const { imagePath, imagePreviewPath, name, formattedIngredients, formattedPrice } = this;

    const photoCocktail = document.createElement('div');
    photoCocktail.classList.add('photo-cocktail');

    const previewPhoto = document.createElement('img');
    previewPhoto.classList.add('photo-cocktail__photo');
    previewPhoto.classList.add('only-screen');
    previewPhoto.src = imagePreviewPath;
    photoCocktail.appendChild(previewPhoto);

    const printPhoto = document.createElement('img');
    printPhoto.classList.add('photo-cocktail__photo');
    printPhoto.classList.add('only-print');
    printPhoto.src = imagePath;
    photoCocktail.appendChild(printPhoto);

    const line = document.createElement('div');
    line.classList.add('photo-cocktail__line');
    photoCocktail.appendChild(line);

    const description = document.createElement('div');
    description.classList.add('photo-cocktail-description');
    photoCocktail.appendChild(description);

    const descriptionHeading = document.createElement('h3');
    descriptionHeading.classList.add('photo-cocktail-description__heading');
    descriptionHeading.innerHTML = name;
    description.appendChild(descriptionHeading);

    const ingredients = document.createElement('div');
    ingredients.classList.add('photo-cocktail-description__ingredients');
    ingredients.innerHTML = formattedIngredients;
    description.appendChild(ingredients);

    if (formattedPrice) {
      const price = document.createElement('div');
      price.classList.add('photo-cocktail-price');
      photoCocktail.appendChild(price);

      const priceNumber = document.createElement('div');
      priceNumber.classList.add('photo-cocktail-price__number');
      priceNumber.innerHTML = formattedPrice;
      price.appendChild(priceNumber);
    }

    return photoCocktail;
    // return examplePhotoCocktailElement.cloneNode(true);
  }

  toRequest(drinkMenu) {
    return {
      data: {
        type: 'photo_cocktail_item',
        attributes: {
          drink_menu_id: drinkMenu.id,
          name: this.name,
          price: this.price === 0 ? 0 : this.price || null,
          position: this.position,
          ingredients: this.ingredients.filter(Boolean),
          photo_cocktail_id: this.originId,

          // only necessary if we enable drag&drop for photococktails
          // origin_position: this.originPosition,
        },
        // relationships: {
        //   photo_cocktail: { "data": { "id": "40", "type": "cocktail" } }
        // }
      },
    };
  }
}
