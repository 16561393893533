const smoothScrollingSupported =
  'scrollBehavior' in document.documentElement.style;

export const scrollTo = (destination = 'top') => {
  const top = 0;
  const bottom = document.body.scrollHeight;

  if (smoothScrollingSupported) {
    window.scroll({
      behavior: 'smooth',
      left: 0,
      top: destination === 'top' ? top : bottom,
    });
  } else {
    window.scroll(0, destination === 'top' ? top : bottom);
  }
};

export const scrollToElement = el => {
  const { offsetTop } = el;
  if (smoothScrollingSupported) {
    window.scroll({
      behavior: 'smooth',
      left: 0,
      top: offsetTop,
    });
  } else {
    window.scroll(0, offsetTop);
  }
};

export const scrollToSelector = (destination = 'body') => {
  const el = document.querySelector(destination);
  scrollToElement(el);
};

export const scrollIntoView = (element, options, alignToTop) => {
  if (smoothScrollingSupported && options) {
    element.scrollIntoView(options);
  } else {
    alignToTop === undefined
      ? element.scrollIntoView()
      : element.scrollIntoView(alignToTop);
  }
};
