// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('@rails/ujs').start();
require('turbolinks').start();
require('@rails/activestorage').start();
require('channels');

import $ from 'jquery';
import 'bootstrap';
import templateFunctions from './templateFunctions';
import materialForm from './material-form';
import '../stylesheets/application';
import "controllers"

document.addEventListener('turbolinks:load', () => {
  templateFunctions();
  $('[data-toggle="tooltip"]').tooltip();
  $('[data-toggle="popover"]').popover({html: true, sanitize: false});
  $('.toast').toast({ autohide: false });
  $('#toast').toast('show');

  // if (document.querySelector('form')) {
  //   materialForm();
  // }
});

// Support component names relative to this directory:
var componentRequireContext = require.context('./react', true);
var ReactRailsUJS = require('react_ujs');
ReactRailsUJS.useContext(componentRequireContext);

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true);
export const imagePath = name => images(name, true);

// direct_uploads.js

document.addEventListener('direct-upload:initialize', event => {
  const { target, detail } = event;
  const { id, file } = detail;
  target.insertAdjacentHTML(
    'beforebegin',
    `
    <div id="direct-upload-${id}" class="direct-upload direct-upload--pending">
      <div id="direct-upload-progress-${id}" class="direct-upload__progress" style="width: 0%"></div>
      <span class="direct-upload__filename"/>
    </div>
  `
  );
  target.previousElementSibling.querySelector(
    `.direct-upload__filename`
  ).textContent = file.name;
});

document.addEventListener('direct-upload:start', event => {
  const { id } = event.detail;
  const element = document.getElementById(`direct-upload-${id}`);
  element.classList.remove('direct-upload--pending');
});

document.addEventListener('direct-upload:progress', event => {
  const { id, progress } = event.detail;
  const progressElement = document.getElementById(
    `direct-upload-progress-${id}`
  );
  progressElement.style.width = `${progress}%`;
});

document.addEventListener('direct-upload:error', event => {
  event.preventDefault();
  const { id, error } = event.detail;
  const element = document.getElementById(`direct-upload-${id}`);
  element.classList.add('direct-upload--error');
  element.setAttribute('title', error);
});

document.addEventListener('direct-upload:end', event => {
  const { id } = event.detail;
  const element = document.getElementById(`direct-upload-${id}`);
  element.classList.add('direct-upload--complete');
});

// document.addEventListener('scroll', () => {
//   const sidebarBrand = document.querySelector('.sidebar-brand');
//   const navbar = document.querySelector('.navbar');
//   const userMenu = document.querySelector('.user-dropdown');
//   const navbarTitle = document.querySelector('.navbar .page-title');

//   if (window.scrollY > 70) {
//     sidebarBrand.classList.remove('bg-dark');
//     navbar.classList.remove('bg-dark');
//     userMenu.classList.add('text-gray-600');
//     navbarTitle.classList.add('text-gray-800');
//   } else {
//     sidebarBrand.classList.add('bg-dark');
//     navbar.classList.add('bg-dark');
//     userMenu.classList.remove('text-gray-600');
//     navbarTitle.classList.remove('text-gray-800');
//   }
// })
