
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "quantity", "price", "unitPrice", "prices", "locale" ]

  connect() {
  }

  calc() {
    const quantity = this.quantityTarget.value
    const locale = this.data.get("locale")
    // unit prices sorted by descending quantity
    const unit_prices = JSON.parse(this.data.get("prices")).sort(function(a, b) {
      if (a.quantity < b.quantity) {
        return 1;
      }
      if (a.quantity > b.quantity) {
        return -1;
      }
      return 0;
    })
    const unit_price_item = unit_prices.find(function(item) {return item.quantity <= quantity})
    const unit_price = unit_price_item.unit_price
    this.unitPriceTarget.textContent = Number(unit_price).toLocaleString(locale)
    const price = Number(unit_price) * Number(quantity)
    this.priceTarget.textContent = Number(price).toLocaleString(locale)
  }
}
