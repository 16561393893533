import React from 'react';
import { PDFDownloadButton } from '../components/PDFDownloadButton';
import Renderer from '../Renderer/Renderer';

export default function PreviewScreen() {
  return (
    <div className="m-auto">
      <div className="mb-4 w-100 d-flex justify-content-center">
        <PDFDownloadButton />
      </div>
      
      <Renderer />
    </div>
  );
}
